import { Link } from 'react-router-dom';
import { useModifyPageTitle } from '../Hooks/hooks';

import type { ReactElement } from 'react';

const Page = (): ReactElement => {
	useModifyPageTitle(`Home`);

	return (
		<div
			className={`
				flex items-center justify-center
				custom-h-full
			`}
		>
			<h2 className="font-extralight text-center">
				<span className="block text-xl">Welcome to the new Internal Dashboard.</span>
				<span className="block mt-2">
					<Link to="/tutorial" className="custom-explicit-link">
						Take the tutorial
					</Link>
					&nbsp;to see how alerts works.
				</span>
			</h2>
		</div>
	);
};

export default Page;
