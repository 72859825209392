import React, { createRef, useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, AlertTriangle, CheckCircle, Loader } from 'react-feather';
import { formatDistance, format } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { handleAPIRequest } from '../../utils/request';
import { log } from '../../utils/logger';

import Button from '../Button/Button';

import type { ReactElement, MouseEventHandler, MouseEvent } from 'react';
import type { HandleAPIRequestError, IHandleAPIRequestResponse } from '../../utils/request';
import type { ReturnTuplePromise } from '../../definitions';

/**
 * Crude implementation of asking the user who they want to tag to the given alert, and posting it to the server.
 * @param alertId - The ID of the alert to tag a user against.
 */
const handleTagUserAction = async (alertId: string): Promise<void> => {
	const userToTag = prompt(`Enter the name of the person to tag: ${alertId}`);
	if (!userToTag) return;

	const emailToTag = `${userToTag}@lettheinsideout.com`;
	const reqBody = { emailToTag };
	const [err, res] = await handleAPIRequest<IActionAPIResponse>({
		method: `POST`,
		url: `alerts/${alertId}/tag-user`,
		reqBody,
	});

	if (err) return void alert(`Error: ${err}`);
	if (res && !res.data.success) {
		if (res.data.error?.id === `USER_NOT_FOUND`) return void alert(`Error: user "${emailToTag}" not found`);
		return void alert(`Error: [${res.data.error?.id}] ${res.data.error?.details}`);
	}
	if (res?.status !== 200) return void alert(`Error: an unknown error occurred`);

	alert(`Tagged "${userToTag}" successfully!`);
};

const AlertVariants = {
	hidden: {
		y: -50,
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
	exited: {
		y: -50,
		opacity: 0,
	},
};

const alertsErrorState = atom<string[]>({
	key: `alerts-error-atom`,
	default: [],
});

interface IAlertAction {
	alertId: string;
	actionId: string;
}

const alertActionsLoadingState = atom<IAlertAction[]>({
	key: `alert-actions-loading-atom`,
	default: [],
});

const alertActionsCompleteState = atom<IAlertAction[]>({
	key: `alert-actions-complete-atom`,
	default: [],
});

interface IAction {
	id: string;
	text: string;
	type?: string;
	loading?: boolean;
	onClick?: MouseEventHandler;
}

type AlertPriority = `STANDARD` | `HIGH`;

interface IActionButton {
	key: number;
	actionId: string;
	className?: string;
	text: string;
	type?: string;
	loading?: boolean;
	alertId: string;
	disabled?: boolean;
	onClick?: MouseEventHandler;
}

interface IActionAPIResponse {
	fulfilled: boolean;
	alert: IAlert;
	response: {
		open?: string;
	};
}

const performLocalAction = async (
	alertId: string,
	actionId: string,
): ReturnTuplePromise<HandleAPIRequestError, IHandleAPIRequestResponse<IActionAPIResponse> | undefined> => {
	const [err, res] = await handleAPIRequest<IActionAPIResponse>({
		method: `POST`,
		url: `alerts/${alertId}/actions/${actionId}`,
	});

	if (err || !res) {
		log.unhappy(`Action request failed`, { alertId, actionId });
		return [err, undefined];
	}

	return [undefined, res];
};

const ActionButton = (props: IActionButton): ReactElement => {
	const [errorState, setErrorState] = useRecoilState(alertsErrorState);
	const [loadingActions, setLoadingActions] = useRecoilState(alertActionsLoadingState);
	const [completedActions, setCompletedActions] = useRecoilState(alertActionsCompleteState);

	const performAction = async (evt: MouseEvent): Promise<MouseEventHandler | undefined> => {
		if (props.onClick) {
			props.onClick(evt);
			return;
		}

		if (!props.type) {
			return;
		}

		if (props.type === `LOCAL`) {
			setLoadingActions([...loadingActions, { alertId: props.alertId, actionId: props.actionId }]);
			const [err, res] = await performLocalAction(props.alertId, props.actionId);
			setLoadingActions(
				loadingActions.filter(({ alertId, actionId }) => alertId !== props.alertId && actionId !== props.actionId),
			);

			if (err || !res || res.status < 200 || res.status >= 300) {
				setErrorState([...errorState, props.alertId]);
				return;
			}

			if (res.data.data.response.open) {
				window.open(res.data.data.response.open);
			}
		}

		setCompletedActions([...completedActions, { alertId: props.alertId, actionId: props.actionId }]);
		return;
	};

	return (
		<Button
			className={props.className}
			variant={props.type === `LOCAL` ? `secondary` : props.type}
			loading={
				props.loading
					? props.loading
					: loadingActions.some(({ alertId, actionId }) => alertId === props.alertId && actionId === props.actionId)
			}
			onClick={performAction}
			disabled={
				props.disabled
					? props.disabled
					: completedActions.some(({ alertId, actionId }) => alertId === props.alertId && actionId === props.actionId)
			}
			text={
				completedActions.some(({ alertId, actionId }) => alertId === props.alertId && actionId === props.actionId) ? (
					<CheckCircle size={20} className="text-green-700 stroke-current" />
				) : (
					props.text
				)
			}
		/>
	);
};

interface ICloseSquare {
	onClick?: MouseEventHandler;
	dismissRef?: React.RefObject<SVGSVGElement>;
	className?: string;
}

const CloseSquare = (props: ICloseSquare): ReactElement => {
	return (
		<svg
			ref={props.dismissRef}
			onClick={props.onClick}
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
			className={`
				text-gray-500
				stroke-current
				cursor-pointer
				${props.className}
			`}
		>
			<rect className="pointer-events-none" x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
			<line className="pointer-events-none" x1="9" y1="9" x2="15" y2="15"></line>
			<line className="pointer-events-none" x1="15" y1="9" x2="9" y2="15"></line>
		</svg>
	);
};

interface IOnDismissCallback {
	(): void;
}

interface IActions {
	alertId: string;
	actions: IAction[];
	actionable?: boolean | undefined;
	dismissable?: boolean | undefined;
	stateChangeInProgress?: boolean;
	onDismiss?: IOnDismissCallback;
	dismissRef?: React.RefObject<SVGSVGElement>;
}

const Actions = (props: IActions): ReactElement => {
	const { alertId, actions, actionable, dismissable, stateChangeInProgress, onDismiss, dismissRef } = props;
	const actionsInProgress = useRecoilValue(alertActionsLoadingState);
	const stateUpdating = stateChangeInProgress ?? actionsInProgress.some(item => item.alertId === alertId);

	return (
		<div
			className={`
				flex items-center
				ml-auto
				pl-2
				h-full
			`}
		>
			<ActionButton
				key={-1}
				actionId="TAG_USER"
				className="ml-2"
				text="@"
				type="TAG_USER"
				loading={false}
				alertId={alertId}
				onClick={() => void handleTagUserAction(alertId)}
			/>
			{actionable &&
				actions.map((action, idx) => (
					<ActionButton
						key={idx}
						actionId={action.id}
						className="ml-2"
						text={action.text}
						type={action.type}
						loading={action.loading}
						alertId={alertId}
						onClick={action.onClick}
					/>
				))}
			{dismissable ? (
				<>
					<div className="mx-3 w-px bg-gray-300 h-3/5" />
					{stateUpdating ? (
						<Loader size={20} className={`stroke-current text-gray-500 animate-spin-slow`} />
					) : (
						<CloseSquare dismissRef={dismissRef} onClick={onDismiss} />
					)}
				</>
			) : (
				<></>
			)}
		</div>
	);
};

interface ITimestampProps {
	dateTimeCreated: string | undefined;
}

const Timestamp = ({ dateTimeCreated }: ITimestampProps): ReactElement | null => {
	if (!dateTimeCreated) return null;

	const date = new Date(dateTimeCreated);
	const now = new Date();
	const stamp = format(date, `HH:mm:ss • dd/LL/yyyy`);
	const distance = formatDistance(date, now, { addSuffix: true }).replace(`less than a minute ago`, `just now`);

	return (
		<span className="text-xs text-gray-400">
			{stamp} • {distance}
		</span>
	);
};

interface IAlertText {
	title: string;
	additional?: {
		name: string;
		body: string;
	};
}

interface Tag {
	alertTagId: string;
	tagAuthId: string;
	tagAuthName: string;
	taggedByAuthId: string;
	taggedByAuthName: string;
	dateTimeCreated: Date;
}

interface IText {
	dateTimeCreated?: string;
	text: IAlertText;
	detailsVisible?: boolean;
	tags: Tag[];
}

const Text = (props: IText): ReactElement => {
	const { dateTimeCreated, text, detailsVisible = false, tags } = props;
	const [showDetails, setShowDetails] = useState<boolean>(detailsVisible);

	return (
		<span>
			<span
				className={`
					${dateTimeCreated ? `text-sm` : `text-lg`} font-light text-gray-600
				`}
			>
				<ReactMarkdown
					components={{
						a: ({ href, children }): ReactElement => (
							<Link className="custom-explicit-link" to={href ?? `#`}>
								{children}
							</Link>
						),
					}}
				>
					{text.title}
				</ReactMarkdown>
			</span>
			{tags.length > 0 && (
				<div className="flex items-center h-6 mt-1">
					<span className="text-xs text-gray-400">
						Tags:&nbsp;
						{tags.map((item, idx) => (
							<span key={idx}>
								{idx === 0 ? `` : ` • `}
								<span className="text-blue-300">@{item.tagAuthName}</span> by @{item.taggedByAuthName}
							</span>
						))}
					</span>
				</div>
			)}
			<div className="flex items-center h-6">
				{text.additional && (
					<>
						<button onClick={(): void => setShowDetails(!showDetails)}>
							<span className="text-xs font-light text-gray-600 underline">
								{showDetails ? `Hide` : `Show`} {text.additional.name}
								<ChevronDown
									size={10}
									strokeWidth={1.5}
									className={`
										inline-block
										text-gray-500
										stroke-current
										cursor-pointer
										ml-0.5
									`}
								/>
							</span>
						</button>
						<div className="mx-2 w-px bg-gray-300 h-1/2 inline-block" />
					</>
				)}
				<Timestamp dateTimeCreated={dateTimeCreated} />
			</div>
			{showDetails && text.additional ? (
				<div
					className={`
						text-sm font-light text-gray-600 mt-2
					`}
				>
					<ReactMarkdown>{text.additional.body}</ReactMarkdown>
				</div>
			) : (
				<></>
			)}
		</span>
	);
};

interface IErrorOverlayProps {
	alertId: string;
}

const ErrorOverlay = (props: IErrorOverlayProps): ReactElement => {
	const [errorState, setErrorState] = useRecoilState(alertsErrorState);

	return (
		<div
			className={`
				absolute
				top-0 left-0
				flex items-center justify-center
				rounded
				w-full
				h-full
				bg-yellow-100
				opacity-90
			`}
		>
			<p>That action couldn't be completed. Please let one of the developers know.</p>
			<CloseSquare
				className="ml-4"
				onClick={(): void => setErrorState(errorState.filter(id => id !== props.alertId))}
			/>
		</div>
	);
};

interface IContent extends IActions, IText {
	alertId: string;
	priority?: AlertPriority;
	disabled?: boolean;
	dismissRef?: React.RefObject<SVGSVGElement>;
}

const Content = (props: IContent): ReactElement => {
	const errorState = useRecoilValue(alertsErrorState);
	const { alertId, priority, disabled, dateTimeCreated, text, detailsVisible, tags, ...rest } = props;

	return (
		<div
			className={`
				relative
				flex justify-between items-center
				w-full
				${priority === `HIGH` ? `bg-red-50` : `bg-gray-50`}
				border ${priority === `HIGH` ? `border-red-500` : `border-gray-400`}
				rounded
				p-4
				transition-opacity
				${disabled ? `opacity-30 pointer-events-none` : ``}
			`}
		>
			{errorState.includes(alertId) && <ErrorOverlay alertId={alertId} />}
			{priority === `HIGH` && <AlertTriangle size={24} className="text-red-500 stroke-current mr-3 mt-3 self-start" />}
			<Text dateTimeCreated={dateTimeCreated} text={text} detailsVisible={detailsVisible} tags={tags} />
			<Actions alertId={alertId} {...rest} />
		</div>
	);
};

const NewDot = (): ReactElement => {
	return (
		<div
			className={`
				absolute -top-2 -left-2
				w-4
				h-4
				rounded-full
				bg-blue-300
				z-10
			`}
		/>
	);
};

export type AlertStatus = `NEW` | `OPEN` | `CLOSED`;

export interface IAlert extends IContent {
	alertId: string;
	status?: AlertStatus;
	onClick?: MouseEventHandler;
	tagged: boolean;
	dottable: boolean;
}

const Alert = (props: IAlert): ReactElement => {
	const { alertId, actions, status, disabled, dottable, onClick, ...rest } = props;
	const dismissRef = createRef();
	const actionsLoading = useRecoilValue(alertActionsLoadingState);
	const actionsComplete = useRecoilValue(alertActionsCompleteState);

	const handleOnClick = (event: MouseEvent): void => {
		if (!onClick) {
			return;
		}

		// Prevent dismiss button from registering two click events:
		if (event.target === dismissRef.current) {
			return;
		}

		// Prevent click event while an action or dismissal is in progress:
		if (props.stateChangeInProgress || actionsLoading.some(item => item.alertId === alertId)) {
			return;
		}

		// Prevent click event when an alert action has completed:
		if (actionsComplete.some(item => item.alertId === alertId)) {
			return;
		}

		setImmediate(() => onClick(event));
	};

	return (
		<motion.div
			layout
			initial="hidden"
			animate="visible"
			exit="exited"
			variants={AlertVariants}
			transition={{ duration: 0.65, type: `spring`, bounce: 0.3 }}
			className={`
				flex
				relative
				w-full
				h-full
				mb-8
			`}
			onClick={(event: MouseEvent): void => handleOnClick(event)}
		>
			{!disabled && dottable && status === `NEW` && <NewDot />}
			<Content
				alertId={alertId}
				actions={actions}
				disabled={disabled}
				{...rest}
				dismissRef={dismissRef as React.RefObject<SVGSVGElement>}
			/>
		</motion.div>
	);
};

export default Alert;
