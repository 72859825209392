import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format as formatDate, subMonths } from 'date-fns';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { DataGrid } from '@mui/x-data-grid';
import { handleAPIRequest } from '../utils/request';
import { log } from '../utils/logger';
import { Loading } from './PatientsDetail';
import { useModifyPageTitle } from '../Hooks/hooks';

import { ChoicePill } from '../Component/ChoicePill';
import type { ReactElement } from 'react';
import type { GridRowsProp, GridColDef } from '@mui/x-data-grid';

const MWEsList = atom<IMWE[]>({
	key: `matching-stats-list`,
	default: [],
});

const MWEsBookingData = atom<IMWEBookingData[]>({
	key: `mwe-booking-data`,
	default: [],
});

export interface IMWE {
	credentialId: number;
	fullName: string;
	role: string;
	timezone: string;
	enabled: boolean;
	consultations: number;
	cancelledConsultations: number;
	regularSessions: number;
	cancelledRegular: number;
}

export interface IMWEBookingData {
	therapistAuthId: string;
	consultations: number;
	cancelledConsultations: number;
	billableRegularSessions: number;
	cancelledRegularSessions: number;
}

interface RecentMonth {
	year: number;
	month: number;
	label: string;
}

const getRecentMonths = (): RecentMonth[] => {
	const today = new Date();
	const months = [];
	for (let i = 1; i < 7; i++) {
		const date = subMonths(today, i);
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		months.push({
			year,
			month,
			label: formatDate(date, `MMMM yyyy`),
		});
	}
	return months;
};

const MWEsTable = (): ReactElement => {
	const data = useRecoilValue(MWEsList);
	const bookingData = useRecoilValue(MWEsBookingData);
	const [searchQuery, setSearchQuery] = useState<string>();
	const [searchFilter, setSearchFilter] = useState(data);

	const history = useHistory();

	const rows: GridRowsProp = searchFilter.map(matchingMWEEntry => {
		const mweBookingData =
			bookingData.filter(x => x.therapistAuthId === matchingMWEEntry.credentialId.toString())[0] ?? null;
		return {
			id: matchingMWEEntry.credentialId,
			name: matchingMWEEntry.fullName,
			credentialId: matchingMWEEntry.credentialId,
			role: matchingMWEEntry.role,
			enabled: matchingMWEEntry.enabled,
			timezone: matchingMWEEntry.timezone,
			consultations: mweBookingData?.consultations ?? 0,
			cancelledConsultations: mweBookingData?.cancelledConsultations ?? 0,
			regularSessions: mweBookingData?.billableRegularSessions ?? 0,
			cancelledRegular: mweBookingData?.cancelledRegularSessions ?? 0,
		};
	});

	const columns: GridColDef[] = [
		{ field: `name`, headerName: `Name`, width: 150 },
		{ field: `enabled`, headerName: `Enabled`, type: `boolean`, width: 100 },
		{ field: `consultations`, headerName: `Consultations`, type: `number`, width: 100 },
		{ field: `cancelledConsultations`, headerName: `Cancelled consultations`, type: `number`, width: 200 },
		{ field: `regularSessions`, headerName: `Regular Sessions`, type: `number`, width: 150 },
		{ field: `cancelledRegular`, headerName: `Cancelled Regular`, type: `number`, width: 150 },
		{ field: `role`, headerName: `Role`, width: 100 },
		{ field: `timezone`, headerName: `Timezone`, width: 150 },
		{ field: `credentialId`, headerName: `CredentialId`, type: `number`, width: 100 },
	];

	const filterMWEs = (mwes: IMWE[], query: string): IMWE[] => {
		if (!query) {
			return mwes;
		}

		return mwes.filter(mweEntry => {
			const name = mweEntry.fullName.toLowerCase();
			const searchTerm = `${name}`;
			return searchTerm.includes(query);
		});
	};

	useEffect(() => {
		if (searchQuery) {
			setSearchFilter(filterMWEs(data, searchQuery.toLowerCase()));
		}
		if (!searchQuery) setSearchFilter(data);
	}, [searchQuery, data]);

	return (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			{/* SEARCH BOX */}
			<div className="flex justify-center">
				<div className="mb-3 xl:w-96">
					<div className="input-group relative flex flex-wrap items-stretch w-full mb-4 rounded">
						<input
							type="search"
							className="form-control relative flex-auto block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							placeholder="Search"
							aria-label="Search"
							onInput={(e): void => setSearchQuery(e.currentTarget.value)}
						/>
					</div>
				</div>
			</div>

			{/* MWE TABLE || NO RESULTS */}
			{searchFilter.length ? (
				<div style={{ height: 600, width: `100%` }}>
					<DataGrid
						onRowClick={(row): void =>
							history.push(`/mwes/${row.id}`, { mweName: row.row.name, mweEnabled: row.row.enabled })
						}
						rows={rows}
						columns={columns}
						initialState={{
							sorting: {
								sortModel: [{ field: `regularSessions`, sort: `desc` }],
							},
						}}
					/>
				</div>
			) : (
				<div className="flex justify-center">
					<span className="text-gray-400">No MWEs to show</span>
				</div>
			)}
		</div>
	);
};

const Page = (): ReactElement => {
	const [loading, setLoading] = useState<boolean>(false);
	const [filterMonthIdx, setFilterMonthIdx] = useState<number | undefined>();
	const setData = useSetRecoilState(MWEsList);
	const setBookingsData = useSetRecoilState(MWEsBookingData);

	const recentMonths = getRecentMonths();

	const pullMWEsData = async (): Promise<void> => {
		setLoading(true);

		const [err, res] = await handleAPIRequest<IMWE[] | undefined>({ method: `get`, url: `mwes` });

		setLoading(false);

		if (err || !res?.data.data) {
			log.unhappy(`Get MWEs request failed`, { error: err });
			return;
		}
		setData(res.data.data);
	};

	const pullMWEsBookingData = async (): Promise<void> => {
		setLoading(true);

		let query = ``;
		if (filterMonthIdx !== undefined && recentMonths[filterMonthIdx]) {
			query = `?month=${recentMonths[filterMonthIdx]?.month}&year=${recentMonths[filterMonthIdx]?.year}`;
		}
		const [err, res] = await handleAPIRequest<IMWEBookingData[] | undefined>({
			method: `GET`,
			url: `booking/therapists/all/${query}`,
			service: `bookings`,
		});

		setLoading(false);

		if (err || !res?.data.data) {
			log.unhappy(`MWE data pull request failed`, { error: err });
			return;
		}

		setBookingsData(res.data.data);
	};

	useModifyPageTitle(`MWEs`);

	useEffect(() => {
		void pullMWEsData();
		void pullMWEsBookingData();
	}, [filterMonthIdx]);

	return loading ? (
		<Loading />
	) : (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			<div
				className={`
			flex
			flex-row
			justify-between
			`}
			>
				<span className="text-2xl">MWEs</span>
			</div>
			<hr className="mt-6 mb-6" />
			<div className="flex items-center">
				<p className="mr-3">Filter MWE patients:</p>
				<ChoicePill
					label="All"
					active={filterMonthIdx === undefined}
					onClick={(): void => setFilterMonthIdx(undefined)}
				/>
				{recentMonths.map((month, idx) => (
					<ChoicePill
						label={month.label}
						active={filterMonthIdx === idx}
						onClick={(): void => setFilterMonthIdx(idx)}
					/>
				))}
			</div>
			<hr className="mt-6 mb-6" />
			<MWEsTable />
		</div>
	);
};

export default Page;
