import AlertFeed from '../Component/Feed/AlertFeed';
import { useModifyPageTitle } from '../Hooks/hooks';

import type { ReactElement } from 'react';

const Alerts = (): ReactElement => {
	useModifyPageTitle(`Alerts`);

	return <AlertFeed mode="ALERTS" />;
};

export default Alerts;
