import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format as formatDate, subMonths } from 'date-fns';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { DataGrid } from '@mui/x-data-grid';
import { Switch } from '@mui/material';
import { handleAPIRequest } from '../utils/request';
import { log } from '../utils/logger';
import { Loading } from './PatientsDetail';
import { useModifyPageTitle } from '../Hooks/hooks';

import { ChoicePill } from '../Component/ChoicePill';
import type { ReactElement } from 'react';
import type { GridRowsProp, GridColDef } from '@mui/x-data-grid';

const MWEPatientsList = atom<ITherapistBookings[]>({
	key: `mwe-patients-list`,
	default: [],
});

export interface IMWE {
	credentialId: number;
	fullName: string;
	role: string;
	timezone: string;
	matchedPatients: number;
	enabled: boolean;
}

export interface ITherapistBookings {
	consultations: number;
	billableRegularSessions: number;
	cancelledConsultations: number;
	cancelledRegularSessions: number;
	patientAuthId: string;
}

const MWEsTable = (): ReactElement => {
	const patientData = useRecoilValue(MWEPatientsList);
	const [searchQuery, setSearchQuery] = useState<string>();
	const [searchFilter, setSearchFilter] = useState(patientData);

	const history = useHistory();

	const rows: GridRowsProp = searchFilter.map(matchingPatientEntry => ({
		id: matchingPatientEntry.patientAuthId,
		consultations: matchingPatientEntry.consultations,
		billableRegularSessions: matchingPatientEntry.billableRegularSessions,
		cancelledConsultations: matchingPatientEntry.cancelledConsultations,
		cancelledRegularSessions: matchingPatientEntry.cancelledRegularSessions,
		patientAuthId: matchingPatientEntry.patientAuthId,
	}));

	const columns: GridColDef[] = [
		// { field: `email`, headerName: `email`, flex: 1, width: 100 },
		{ field: `patientAuthId`, headerName: `PatientAuthId`, type: `number`, width: 150 },
		{ field: `consultations`, headerName: `Consultations`, type: `number`, width: 150 },
		{ field: `billableRegularSessions`, headerName: `Billable Regular Sessions`, type: `number`, width: 200 },
		{ field: `cancelledConsultations`, headerName: `Cancelled Consultations`, type: `number`, width: 250 },
		{ field: `cancelledRegularSessions`, headerName: `Cancelled Regular Sessions`, type: `number`, width: 250 },
	];

	const filterPatients = (patients: ITherapistBookings[], query: string): ITherapistBookings[] => {
		if (!query) {
			return patients;
		}

		return patients.filter(patientEntry => {
			const name = patientEntry.patientAuthId.toLowerCase();
			const searchTerm = `${name}`;
			return searchTerm.includes(query);
		});
	};

	useEffect(() => {
		if (searchQuery) {
			setSearchFilter(filterPatients(patientData, searchQuery.toLowerCase()));
		}
		if (!searchQuery) setSearchFilter(patientData);
	}, [searchQuery, patientData]);

	return (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			{/* SEARCH BOX */}
			<div className="flex justify-center">
				<div className="mb-3 xl:w-96">
					<div className="input-group relative flex flex-wrap items-stretch w-full mb-4 rounded">
						<input
							type="search"
							className="form-control relative flex-auto block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							placeholder="Search"
							aria-label="Search"
							onInput={(e): void => setSearchQuery(e.currentTarget.value)}
						/>
					</div>
				</div>
			</div>

			{/* MWE TABLE || NO RESULTS */}
			{searchFilter.length ? (
				<div style={{ height: 600, width: `100%` }}>
					<DataGrid
						onRowClick={(row): void => history.push(`/patients/${row.id}`)}
						rows={rows}
						columns={columns}
						initialState={{
							sorting: { sortModel: [{ field: `patientAuthId`, sort: `asc` }] },
						}}
					/>
				</div>
			) : (
				<div className="flex justify-center">
					<span className="text-gray-400">No MWEs to show</span>
				</div>
			)}
		</div>
	);
};

interface RecentMonth {
	year: number;
	month: number;
	label: string;
}

const getRecentMonths = (): RecentMonth[] => {
	const today = new Date();
	const months = [];
	for (let i = 1; i < 7; i++) {
		const date = subMonths(today, i);
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		months.push({
			year,
			month,
			label: formatDate(date, `MMMM yyyy`),
		});
	}
	return months;
};

interface IParams {
	authId: string;
}

interface HistoryProps {
	mweName: string;
	mweEnabled: boolean;
}

const Page = (): ReactElement => {
	const { authId } = useParams<IParams>();
	const [loading, setLoading] = useState<boolean>(false);
	const [filterMonthIdx, setFilterMonthIdx] = useState<number | undefined>();
	const history = useHistory<HistoryProps>();
	const { mweName, mweEnabled } = history.location.state;
	const [enabled, setEnabled] = useState<boolean>(mweEnabled);
	const [enabledLoading, setEnabledLoading] = useState<boolean>(false);

	const recentMonths = getRecentMonths();
	const setPatientsData = useSetRecoilState(MWEPatientsList);

	const pullMWEsPatientsData = async (): Promise<void> => {
		setLoading(true);

		let query = ``;
		if (filterMonthIdx !== undefined && recentMonths[filterMonthIdx]) {
			query = `?month=${recentMonths[filterMonthIdx]?.month}&year=${recentMonths[filterMonthIdx]?.year}`;
		}
		const [err, res] = await handleAPIRequest<ITherapistBookings[] | undefined>({
			method: `GET`,
			url: `booking/therapist/${authId}${query}`,
			service: `bookings`,
		});

		setLoading(false);

		if (err || !res?.data.data) {
			log.unhappy(`MWE data pull request failed`, { error: err });
			return;
		}

		setPatientsData(res.data.data);
	};

	useModifyPageTitle(`MWEs`);

	useEffect(() => {
		void pullMWEsPatientsData();
	}, [filterMonthIdx]);

	const handleOnChangeEnabled = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		setEnabledLoading(true);

		const [err] = await handleAPIRequest<unknown | undefined>({
			method: `PUT`,
			url: `mwes/${authId}?enabled=${event.target.checked}`,
		});

		if (err) {
			log.unhappy(`Update MWE enabled failed`, { error: err });
			return;
		} else {
			setEnabled(!event.target.checked);
		}
		setEnabledLoading(false);
	};

	return loading ? (
		<Loading />
	) : (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			<div
				className={`
			flex
			flex-row
			justify-between
			`}
			>
				<span className="text-2xl">{mweName}</span>
				<div>
					{enabledLoading ? (
						<Loading />
					) : (
						<div>
							<span className="text-2xl">{enabled ? `Enabled` : `Disabled`}</span>
							<Switch checked={enabled} onChange={handleOnChangeEnabled} />
						</div>
					)}
				</div>
			</div>
			<hr className="mt-6 mb-6" />
			<div className="flex items-center">
				<p className="mr-3">Filter MWE patients:</p>
				<ChoicePill
					label="All"
					active={filterMonthIdx === undefined}
					onClick={(): void => setFilterMonthIdx(undefined)}
				/>
				{recentMonths.map((month, idx) => (
					<ChoicePill
						label={month.label}
						active={filterMonthIdx === idx}
						onClick={(): void => setFilterMonthIdx(idx)}
					/>
				))}
			</div>
			<hr className="mt-6 mb-6" />
			<MWEsTable />
		</div>
	);
};

export default Page;
