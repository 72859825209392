import { useEffect, useState } from 'react';
import { InlineCheckBox } from '../CheckBox';
import Button from '../Button/Button';

import { handleAPIRequest } from '../../utils/request';

import type { ReactElement } from 'react';
import type { IClient } from '../../Page/Clients';

interface IEditSubscriptionModalProps {
	clientId: string;
	uponSuccessCallback: (response: IClient) => void;
	sessionsThreshold: {
		consultations: number;
		regular: number;
	};
}

const EditSessionsAlertThresholdModalContent = ({
	clientId,
	uponSuccessCallback,
	sessionsThreshold,
}: IEditSubscriptionModalProps): ReactElement => {
	const [errored, setErrored] = useState(false);
	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [consultations, setConsultations] = useState(sessionsThreshold.consultations);
	const [regularSessions, setRegularSessions] = useState(sessionsThreshold.regular);
	const [consultationsEnabled, setConsultationsEnabled] = useState(sessionsThreshold.consultations !== -1);
	const [regularSessionsEnabled, setRegularSessionsEnabled] = useState(sessionsThreshold.regular !== -1);
	const [response, setResponse] = useState<IClient>();

	const changed = sessionsThreshold.consultations !== consultations || sessionsThreshold.regular !== regularSessions;

	useEffect(() => {
		setConsultations(consultationsEnabled ? (consultations !== -1 ? consultations : 1) : -1);
	}, [consultationsEnabled]);

	useEffect(() => {
		setRegularSessions(regularSessionsEnabled ? (regularSessions !== -1 ? regularSessions : 1) : -1);
	}, [regularSessionsEnabled]);

	const handleConsultationsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setConsultations(parseInt(e.target.value));
	};

	const handleRegularSessionsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setRegularSessions(parseInt(e.target.value));
	};

	const handleConsultationsEnabledChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setConsultationsEnabled(e.target.checked);
	};

	const handleRegularSessionsEnabledChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setRegularSessionsEnabled(e.target.checked);
	};

	const updateSessionsAlertThreshold = async (): Promise<void> => {
		const reqBody = {
			client: {
				consultationsThreshold: consultations,
				regularSessionsThreshold: regularSessions,
			},
		};
		setErrored(false);
		setLoading(true);

		const [err, res] = await handleAPIRequest<IClient>({
			method: `PUT`,
			url: `clients/${clientId}`,
			reqBody,
		});
		setLoading(false);
		if (err || res?.status !== 200) {
			setErrored(true);
			return;
		}
		setResponse(res.data.data);
		setCompleted(true);
	};

	useEffect(() => {
		if (completed && response) uponSuccessCallback(response);
	}, [completed]);

	return (
		<>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="block items-center">
				<InlineCheckBox
					id="enable-consultations"
					checked={consultationsEnabled}
					onChange={handleConsultationsEnabledChange}
					label="Enable Consultations Threshold"
					highlight={false}
				/>
				{consultationsEnabled && (
					<>
						<div className="mr-8">
							<span className="text-sm flex-shrink-0">Minimum Consultations:</span>
						</div>
						<input
							type="number"
							className="w-12 py-1 px-2 text-sm inline-block rounded border-gray-400"
							min={1}
							value={consultations}
							onChange={handleConsultationsChange}
						/>
					</>
				)}
				<hr className="w-72 mt-5 mb-5" />
				<InlineCheckBox
					id="enable-regular-sessions"
					checked={regularSessionsEnabled}
					onChange={handleRegularSessionsEnabledChange}
					label="Enable Regular Sessions Threshold"
					highlight={false}
				/>
				{regularSessionsEnabled && (
					<>
						<div className="mr-8">
							<span className="text-sm flex-shrink-0">Mimimum Regular Sessions:</span>
						</div>
						<input
							type="number"
							min={1}
							className="w-12 py-1 px-2 text-sm inline-block rounded border-gray-400"
							value={regularSessions}
							onChange={handleRegularSessionsChange}
						/>
					</>
				)}
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={updateSessionsAlertThreshold}
				disabled={!changed}
				text="Update"
			/>
		</>
	);
};

export default EditSessionsAlertThresholdModalContent;
