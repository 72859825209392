import { useState } from 'react';
import DatePicker from 'react-date-picker';
import Button from '../Button/Button';

import { handleAPIRequest } from '../../utils/request';

import type { ReactElement } from 'react';

interface ExportBookingsModalContentProps {
	enterpriseId?: string;
	uponSuccessCallback: () => void;
}

const ExportBookingsModalContent = ({
	enterpriseId,
	uponSuccessCallback,
}: ExportBookingsModalContentProps): ReactElement => {
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState<Date | null>();
	const [endDate, setEndDate] = useState<Date | null>();
	const [error, setError] = useState(``);

	const downloadBookingStats = async (): Promise<void> => {
		setError(``);
		setLoading(true);

		let url = `reports/patients/bookings?`;
		if (enterpriseId) url += `enterpriseId=${enterpriseId}&`;
		if (startDate) url += `startDate=${startDate.toISOString().substring(0, 10)}&`;
		if (endDate) {
			const inclusiveEndDate = new Date(endDate);
			inclusiveEndDate.setDate(endDate.getDate() + 1);
			url += `endDate=${inclusiveEndDate.toISOString().substring(0, 10)}`;
		}
		const [err, res] = await handleAPIRequest({ method: `GET`, url });
		setLoading(false);

		// This is purely for TypeScript correctness:
		if (err || res === undefined || res.status !== 200 || !res.data.data.csvReport) {
			setError(err ?? `Something went wrong!`);
			return;
		}

		// Convert to CSV:
		const csv = `data:text/csv;charset=utf-8,${res.data.data.csvReport}`;

		// Trigger download:
		const encodedUri = encodeURI(csv);
		const link = document.createElement(`a`);
		link.setAttribute(`href`, encodedUri);
		link.setAttribute(`download`, `bookings_stats_for_client_${enterpriseId ?? `all`}.csv`);
		document.body.appendChild(link);
		link.click();

		uponSuccessCallback();
	};

	return (
		<>
			{error && (
				<div
					className={`
						flex items-center justify-center
						rounded
						w-full
						p-2
						mb-3
						bg-yellow-100
						opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Start Date?:</span>
				<DatePicker value={startDate} onChange={setStartDate} />
			</div>
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">End Date?:</span>
				<DatePicker value={endDate} onChange={setEndDate} />
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={downloadBookingStats}
				text={`Download`}
			/>
		</>
	);
};

export default ExportBookingsModalContent;
