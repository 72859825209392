import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router, Link, Route, Switch, useLocation } from 'react-router-dom';

import TutorialPage from './Page/Tutorial';
import HomePage from './Page/Home';
import LoginPage from './Page/Login';
import LogoutPage from './Page/Logout';
import AlertPage from './Page/Alerts';
import NotificationsPage from './Page/Notifications';
import PatientsPage from './Page/Patients';
import PatientsDetailPage from './Page/PatientsDetail';
import ClientsPage from './Page/Clients';
import ClientsDetailPage from './Page/ClientsDetail';
import MatchingStatsPage from './Page/MatchingStats';
import MWEsPage from './Page/MWEs';
import MWEsDetailsPage from './Page/MWEsDetail';
import ProtectedRoute from './Route/ProtectedRoute';
import { getEmailFromState } from './utils/auth';

import type { ReactElement } from 'react';

const AppHeader = (): ReactElement => {
	const location = useLocation();

	return (
		<div
			className={`
				fixed
				flex items-center justify-between
				w-full
				px-4
				h-12
				border-b
				bg-white
				z-10
			`}
		>
			<div className="flex h-full items-center justify-center">
				<Link to="/">
					<h2>
						<span className="font-extralight">IDB</span>
						&nbsp;
						<span className="text-xs font-extralight">beta</span>
					</h2>
				</Link>
				<div className="mx-3 h-2/5 w-px bg-gray-400" />
				<Link to="/alerts">
					<h3
						className={`
							text-sm
							font-extralight
							${location.pathname === `/alerts` ? `custom-explicit-link` : ``}
						`}
					>
						Alerts
					</h3>
				</Link>
				<Link to="/notifications">
					<h3
						className={`
							text-sm
							font-extralight
							ml-5
							${location.pathname === `/notifications` ? `custom-explicit-link` : ``}
						`}
					>
						Notifications
					</h3>
				</Link>
				<Link to="/patients">
					<h3
						className={`
							text-sm
							font-extralight
							ml-5
							${location.pathname.startsWith(`/patients`) ? `custom-explicit-link` : ``}
						`}
					>
						Patients
					</h3>
				</Link>
				<Link to="/clients">
					<h3
						className={`
							text-sm
							font-extralight
							ml-5
							${location.pathname.startsWith(`/clients`) ? `custom-explicit-link` : ``}
						`}
					>
						Clients
					</h3>
				</Link>
				<Link to="/matching">
					<h3
						className={`
							text-sm
							font-extralight
							ml-5
							${location.pathname.startsWith(`/matching`) ? `custom-explicit-link` : ``}
						`}
					>
						Matching Stats
					</h3>
				</Link>
				<Link to="/mwes">
					<h3
						className={`
							text-sm
							font-extralight
							ml-5
							${location.pathname.startsWith(`/mwes`) ? `custom-explicit-link` : ``}
						`}
					>
						MWEs
					</h3>
				</Link>
			</div>
			<h2>
				<span className="font-extralight text-sm">
					<span className="hidden md:inline">{getEmailFromState()} -&nbsp;</span>
					<Link to="/logout" className="underline">
						Log Out
					</Link>
				</span>
			</h2>
		</div>
	);
};

const AppFrame = (): ReactElement => {
	return (
		<div className="pt-12 h-full">
			<Route path="/tutorial">
				<TutorialPage />
			</Route>
			<Route exact path="/logout">
				<LogoutPage />
			</Route>
			<Route exact path="/alerts">
				<AlertPage />
			</Route>
			<Route exact path="/notifications">
				<NotificationsPage />
			</Route>
			<Route exact path="/patients">
				<PatientsPage />
			</Route>
			<Route path="/patients/:authId">
				<PatientsDetailPage />
			</Route>
			<Route exact path="/clients">
				<ClientsPage />
			</Route>
			<Route path="/clients/:clientId">
				<ClientsDetailPage />
			</Route>
			<Route exact path="/matching">
				<MatchingStatsPage />
			</Route>
			<Route exact path="/mwes">
				<MWEsPage />
			</Route>
			<Route exact path="/mwes/:authId">
				<MWEsDetailsPage />
			</Route>
			<Route exact path="/">
				<HomePage />
			</Route>
		</div>
	);
};

const Routes = (): ReactElement => {
	return (
		<Switch>
			<Route exact path="/login">
				<LoginPage />
			</Route>
			<ProtectedRoute>
				<AppHeader />
				<AppFrame />
			</ProtectedRoute>
		</Switch>
	);
};

const App = (): ReactElement => {
	return (
		<div className="h-full">
			<RecoilRoot>
				<Router>
					<Routes />
				</Router>
			</RecoilRoot>
		</div>
	);
};

export default App;
