import { useState } from 'react';
import { format as formatDate, parse as parseDate, isValid, addMonths, formatISO } from 'date-fns';
import { InlineCheckBox } from '../CheckBox';
import Button from '../Button/Button';

import { handleAPIRequest } from '../../utils/request';

import type { ReactElement } from 'react';
import type { ISubscription } from '../../Page/Clients';

interface AddSubscriptionModalContentProps {
	entity: `CLIENT` | `USER`;
	entityId: string;
	uponSuccessCallback: (response: ISubscription) => void;
}

const AddSubscriptionModalContent = ({
	entity,
	entityId,
	uponSuccessCallback,
}: AddSubscriptionModalContentProps): ReactElement => {
	const [fromDate, setFromDate] = useState(formatDate(new Date(), `dd/MM/yyyy`));
	const [toDate, setToDate] = useState(formatDate(addMonths(new Date(), 12), `dd/MM/yyyy`));
	const [title, setTitle] = useState(``);
	const [initialConsultationSessions, setInitialConsultationSessions] = useState(0);
	const [initialRegularSessions, setInitialRegularSessions] = useState(0);
	const [therapyEnabled, setTherapyEnabled] = useState(true);
	const [mfpEnabled, setMfpEnabled] = useState(true);

	const [errored, setErrored] = useState(false);
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState<ISubscription | undefined>();
	const [completed, setCompleted] = useState(false);

	const finishedUpdate = !loading && !errored && completed;
	const fromDateIsValid = isValid(parseDate(fromDate, `dd/MM/yyyy`, new Date()));
	const toDateIsValid = isValid(parseDate(toDate, `dd/MM/yyyy`, new Date()));
	const initialSessionsIsValid = !isNaN(initialConsultationSessions) && !isNaN(initialRegularSessions);
	const canSubmit = fromDateIsValid && toDateIsValid && title && initialSessionsIsValid;

	if (finishedUpdate && response) {
		uponSuccessCallback(response);
	}

	const addSubscription = async (): Promise<void> => {
		const subscription: ISubscription = {
			title,
			kind:
				entity === `CLIENT`
					? {
							type: `CLIENT`,
							clientId: entityId,
					  }
					: {
							type: `PERSONAL`,
							patientAuthId: entityId,
					  },
			consultationSessions: {
				initialQuantity: initialConsultationSessions,
				additionalQuantity: 0,
				typesOfTherapy: {
					therapy: therapyEnabled,
					mfp: mfpEnabled,
					coaching: therapyEnabled, // this should mirror therapyEnabled (for now)
				},
			},
			regularSessions: {
				initialQuantity: initialRegularSessions,
				additionalQuantity: 0,
				typesOfTherapy: {
					therapy: therapyEnabled,
					mfp: mfpEnabled,
					coaching: therapyEnabled, // this should mirror therapyEnabled (for now)
				},
			},
			dateTimeStart: formatISO(parseDate(fromDate, `dd/MM/yyyy`, new Date())),
			dateTimeEnd: formatISO(parseDate(toDate, `dd/MM/yyyy`, new Date())),
		};

		const reqBody = {
			subscription,
		};

		setErrored(false);
		setLoading(true);
		const [err, res] = await handleAPIRequest<ISubscription>({
			service: `bookings`,
			method: `POST`,
			url: `subscription`,
			reqBody,
		});
		setLoading(false);

		if (err || res?.status !== 200) {
			setErrored(true);
			setCompleted(true);
			return;
		}

		setResponse(res.data.data);
		setCompleted(true);
	};

	return (
		<>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="flex items-center">
				<span className="text-sm flex-shrink-0 mr-3">Title:</span>
				<input
					type="text"
					className="w-auto py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={title}
					placeholder="e.g. 12 month subscription"
					onChange={(e): void => setTitle(e.target.value)}
				/>
			</div>
			<hr className="w-72 mt-5 mb-5" />
			<div className="flex items-center">
				<span className="text-sm flex-shrink-0 mr-3">From:</span>
				<input
					type="text"
					className="w-28 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={fromDate}
					onChange={(e): void => setFromDate(e.target.value)}
				/>
				<span className="text-sm flex-shrink-0 mx-3">to:</span>
				<input
					type="text"
					className="w-28 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={toDate}
					onChange={(e): void => setToDate(e.target.value)}
				/>
			</div>
			<hr className="w-72 mt-5 mb-5" />
			<div className="block items-center">
				<div className="mr-3">
					<span className="text-sm flex-shrink-0">Initial Consultation Sessions:</span>
				</div>
				<input
					type="text"
					className="w-12 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={initialConsultationSessions}
					onChange={(e): void => setInitialConsultationSessions(parseInt(e.target.value))}
				/>
				<div className="mr-3">
					<span className="text-sm flex-shrink-0">Initial Regular Sessions:</span>
				</div>
				<input
					type="text"
					className="w-12 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={initialRegularSessions}
					onChange={(e): void => setInitialRegularSessions(parseInt(e.target.value))}
				/>
			</div>
			<hr className="w-72 mt-5 mb-5" />
			<div className="flex items-center">
				<InlineCheckBox
					id="enable-therapy"
					checked={therapyEnabled}
					onChange={(e): void => setTherapyEnabled(e.target.checked)}
					label="Enable Therapy"
					highlight={false}
				/>
				<InlineCheckBox
					id="enable-mfp"
					checked={mfpEnabled}
					onChange={(e): void => setMfpEnabled(e.target.checked)}
					label="Enable MFP"
					highlight={false}
					className="ml-3"
				/>
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={addSubscription}
				disabled={!canSubmit}
				text="Add"
			/>
		</>
	);
};

export default AddSubscriptionModalContent;
