import { useEffect, useState } from 'react';
import { useRecoilState, atom } from 'recoil';
import { Loader } from 'react-feather';

import { handleAPIRequest } from '../../utils/request';
import { log } from '../../utils/logger';
import AlertList from '../Alert/AlertList';
import Alert from '../Alert/Alert';
import AlertFilter, { getAlertListToDisplay } from '../Alert/AlertFilter';

import type { ReactElement, EffectCallback } from 'react';
import type { IAlert, AlertStatus } from '../Alert/Alert';
import type { AlertGetMode, AlertFilterType } from '../Alert/AlertFilter';

const DEFAULT_WINDOW_TITLE = `IDB`;

interface IAlertFeedProps {
	mode: AlertGetMode;
}

const AlertFeedData = atom<IAlert[] | undefined>({
	key: `alert-feed-data`,
	default: [],
});

interface QueuedChangeAlertStateRequest {
	status: AlertStatus;
	alertId: string;
}

/**
 * Sets the window title based on the mode and the number of alerts.
 * @param mode - The mode for getting the alerts.
 * @param allAlertCount - The total number of alerts.
 * @param urgentAlertCount - The number of urgent alerts.
 * @param taggedAlertCount - The number of tagged alerts.
 */
const setWindowTitle = (
	mode: AlertGetMode,
	allAlertCount?: number,
	urgentAlertCount?: number,
	taggedAlertCount?: number,
): void => {
	if (mode !== `ALERTS` || allAlertCount === undefined) return void (window.document.title = DEFAULT_WINDOW_TITLE);
	window.document.title = `${DEFAULT_WINDOW_TITLE} (${urgentAlertCount}/${taggedAlertCount}/${allAlertCount})`;
};

export const AlertFeed = ({ mode }: IAlertFeedProps): ReactElement => {
	const [data, setData] = useRecoilState(AlertFeedData);
	const [alertsPulling, setAlertsPulling] = useState(false);
	const [changeAlertStateLoading, setChangeAlertStateLoading] = useState<string[]>([]);
	const [queuedChangeAlertStateRequests, setQueuedChangeAlertStateRequests] = useState<QueuedChangeAlertStateRequest[]>(
		[],
	);
	const [alertFilter, setAlertFilter] = useState<AlertFilterType>(`URGENT`);

	/**
	 * Pull latest alerts from the IDB
	 */
	const pullAlerts = async (): Promise<void> => {
		if (alertsPulling) {
			// Skip alert pull if request in progress
			return;
		}

		setAlertsPulling(true);

		const apiPath = `alerts${mode === `NOTIFICATIONS` ? `/notifications` : ``}`;
		const [err, res] = await handleAPIRequest<IAlert[] | undefined>({ method: `get`, url: apiPath });
		setAlertsPulling(false);

		if (err) {
			log.unhappy(`Alert pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		setData(res.data.data);
	};

	/**
	 * Set the window unload handler referencing the number of queued items
	 */
	const setWindowUnloadHandler = (): void => {
		window.onbeforeunload = (): string => {
			return `Are you sure you want to close this window?\n\nThere are ${queuedChangeAlertStateRequests.length} alert state changes in progress.`;
		};
	};

	/**
	 * Unset the window unload handler
	 */
	const unsetWindowUnloadHandler = (): void => {
		window.onbeforeunload = null;
	};

	/**
	 * Queue a request to change an alert's state
	 * @param status - Status to change to, e.g. OPEN or CLOSED
	 * @param alertId - ID of the alert to change
	 */
	const queueChangeAlertStateRequest = (status: AlertStatus, alertId: string): void => {
		const localAlert = data?.find(alert => alert.alertId === alertId);

		if (localAlert && localAlert.status === status) {
			// Skip if alert is already in desired state
			return;
		}

		setWindowUnloadHandler();
		setQueuedChangeAlertStateRequests([...queuedChangeAlertStateRequests, { status, alertId }]);
	};

	/**
	 * Update an alert's local state
	 * @param status - Status to change to, e.g. OPEN or CLOSED
	 * @param alertId - ID of the alert to change
	 */
	const updateLocalAlertState = (status: AlertStatus, alertId: string): void => {
		if (!data) {
			return;
		}

		const updatedData = data.map(alert => {
			if (alert.alertId === alertId) {
				return { ...alert, status };
			}
			return alert;
		});

		setData(updatedData);
	};

	/**
	 * Change an alert's state, e.g. from OPEN to CLOSED
	 * @param status - Status to change to, e.g. OPEN or CLOSED
	 * @param alertId - ID of the alert to change
	 */
	const changeAlertState = async (status: AlertStatus, alertId: string): Promise<void> => {
		setChangeAlertStateLoading([...changeAlertStateLoading, alertId]);

		const [err] = await handleAPIRequest({
			method: `PATCH`,
			url: `alerts/${alertId}`,
			reqBody: {
				status,
			},
		});

		updateLocalAlertState(status, alertId);

		setChangeAlertStateLoading(changeAlertStateLoading.filter(id => id !== alertId));

		if (err) {
			log.unhappy(`Request to change alert state failed`, { alertId });
			return;
		}

		// Remove this alert state request from the queue:
		setQueuedChangeAlertStateRequests(
			queuedChangeAlertStateRequests.filter(
				({ status: alertStatus, alertId: id }) => id !== alertId && alertStatus !== status,
			),
		);
	};

	/**
	 * Hook to process next queue item upon a change to either the queue, or to the
	 * alert pulling loading state. Skip process if there are no items in the queue,
	 * alerts are being pulled, or the pertaining alert is already loading
	 */
	const processNextQueueItem = (): void => {
		const nextQueueItem = queuedChangeAlertStateRequests[0];

		if (!nextQueueItem) {
			unsetWindowUnloadHandler();
			return;
		}

		if (alertsPulling) {
			return;
		}

		if (changeAlertStateLoading.includes(nextQueueItem.alertId)) {
			return;
		}

		void changeAlertState(nextQueueItem.status, nextQueueItem.alertId);
	};

	/**
	 * Hook to poll alerts immediately and at set intervals afterwards
	 */
	const pollAlerts = (): ReturnType<EffectCallback> => {
		const alertPullInterval = setInterval(pullAlerts, 10000);
		void pullAlerts();

		// This is to clear the timeout upon un-mount:
		return (): void => clearInterval(alertPullInterval);
	};

	useEffect(processNextQueueItem, [queuedChangeAlertStateRequests, alertsPulling]);
	useEffect(pollAlerts, []);

	if (!data || !data.length) {
		setWindowTitle(mode);
		return (
			<div
				className={`
        mt-24 mx-auto
        p-4
        custom-width-1080
      `}
			>
				<p
					className={`
            text-center
            text-gray-500
            font-extralight
            mx-auto
          `}
				>
					You don't have any alerts right now.
					<br />
					This page will periodically check on your behalf.
				</p>
				<Loader size={17} className={`stroke-current text-gray-500 animate-spin-slow mt-3 mx-auto`} />
			</div>
		);
	}

	const [list, allAlertCount, urgentAlertCount, taggedAlertCount] = getAlertListToDisplay(data, mode, alertFilter);
	setWindowTitle(mode, allAlertCount, urgentAlertCount, taggedAlertCount);

	return (
		<AlertList>
			{mode === `ALERTS` && (
				<AlertFilter
					alertFilter={alertFilter}
					setAlertFilter={setAlertFilter}
					allAlertCount={allAlertCount}
					urgentAlertCount={urgentAlertCount}
					taggedAlertCount={taggedAlertCount}
				/>
			)}
			{list.map(alert => (
				<Alert
					key={alert.alertId}
					alertId={alert.alertId}
					text={alert.text}
					priority={alert.priority}
					actions={alert.actions}
					dateTimeCreated={alert.dateTimeCreated}
					actionable={mode === `ALERTS`}
					dismissable={mode === `NOTIFICATIONS` ? false : alert.dismissable === undefined ? true : alert.dismissable}
					dottable={mode === `ALERTS`}
					detailsVisible={mode === `NOTIFICATIONS`}
					stateChangeInProgress={changeAlertStateLoading.includes(alert.alertId)}
					status={alert.status}
					tagged={alert.tagged}
					tags={alert.tags}
					onClick={mode === `ALERTS` ? (): void => queueChangeAlertStateRequest(`OPEN`, alert.alertId) : undefined}
					onDismiss={(): void => queueChangeAlertStateRequest(`CLOSED`, alert.alertId)}
				/>
			))}
		</AlertList>
	);
};

export default AlertFeed;
