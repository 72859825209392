const { REACT_APP_API_URL, REACT_APP_BOOKINGS_API_URL, REACT_APP_AUTH_URL } = process.env;

if (!REACT_APP_API_URL) throw new Error(`MISSING ENV: REACT_APP_API_URL`);
if (!REACT_APP_AUTH_URL) throw new Error(`MISSING ENV: REACT_APP_AUTH_URL`);

const config = {
	API: {
		URL: REACT_APP_API_URL,
		BOOKINGS_URL: REACT_APP_BOOKINGS_API_URL,
	},
	AUTH_URL: REACT_APP_AUTH_URL,
};

export default config;
