import { InlineCheckBox } from '../CheckBox';

import type { ReactElement } from 'react';
import type { IAlert } from '../Alert/Alert';

export type AlertGetMode =
	| `ALERTS` // Show only alerts.
	| `NOTIFICATIONS`; // Show all notifications.

/**
 * Returns true if the given alert is an urgent one.
 * @param alert - The alert to check.
 */
export const isAlertUrgent = (alert: IAlert): boolean => {
	return Boolean(alert.priority === `HIGH` || alert.actions.length);
};

/**
 * Filters the list of alerts based on the given filter and returns the filtered list and number of all/urgent/tagged
 * alerts. If the mode is NOTIFICATIONS, all alerts are returned with no filtering.
 * @param alerts - The list of alerts to filter.
 * @param mode - The mode for getting the alerts.
 * @param alertFilter - The filter to apply.
 */
export const getAlertListToDisplay = (
	alerts: IAlert[],
	mode: AlertGetMode,
	alertFilter: AlertFilterType,
): [IAlert[], number, number, number] => {
	if (mode === `NOTIFICATIONS`) return [alerts, 0, 0, 0];

	const output: IAlert[] = [];
	let allAlertCount = 0;
	let urgentAlertCount = 0;
	let taggedAlertCount = 0;

	for (const alert of alerts) {
		const isOpen = alert.status !== `CLOSED`;
		const isUrgent = isOpen && isAlertUrgent(alert);
		const isTagged = isOpen && alert.tagged;

		if (isOpen) allAlertCount++;
		if (isUrgent) urgentAlertCount++;
		if (isTagged) taggedAlertCount++;

		switch (alertFilter) {
			case `URGENT`:
				if (isUrgent) output.push(alert);
				break;

			case `TAGGED`:
				if (isTagged) output.push(alert);
				break;

			case `ALL`:
				if (isOpen) output.push(alert);
				break;
		}
	}

	return [output, allAlertCount, urgentAlertCount, taggedAlertCount];
};

// Represents the possible filter types for alerts.
export type AlertFilterType = `URGENT` | `ALL` | `TAGGED`;

export interface IAlertFilterProps {
	alertFilter: AlertFilterType;
	setAlertFilter: (alertFilter: AlertFilterType) => void;
	allAlertCount: number;
	urgentAlertCount: number;
	taggedAlertCount: number;
}

const AlertFilter = ({
	alertFilter,
	setAlertFilter,
	allAlertCount,
	urgentAlertCount,
	taggedAlertCount,
}: IAlertFilterProps): ReactElement => {
	return (
		<div className="mb-8 text-left">
			<InlineCheckBox
				id="urgent-alerts"
				checked={alertFilter === `URGENT`}
				onChange={(): void => setAlertFilter(`URGENT`)}
				label={`Urgent (${urgentAlertCount})`}
				highlight={urgentAlertCount > 0}
			/>
			<InlineCheckBox
				id="tagged-alerts"
				className="ml-5"
				checked={alertFilter === `TAGGED`}
				onChange={(): void => setAlertFilter(`TAGGED`)}
				label={`Tagged (${taggedAlertCount})`}
				highlight={taggedAlertCount > 0}
			/>
			<InlineCheckBox
				id="all-alerts"
				className="ml-5"
				checked={alertFilter === `ALL`}
				onChange={(): void => setAlertFilter(`ALL`)}
				label={`All (${allAlertCount})`}
				highlight={false}
			/>
		</div>
	);
};

export default AlertFilter;
