import { Promise as PromiseBlueBird } from 'bluebird';

export const waitAll = async (tasks: (() => Promise<any>)[], parallel?: number): Promise<any[]> => {
	const responses = await PromiseBlueBird.map(
		tasks,
		async (task: () => Promise<any>) => {
			const result = await task();
			return result;
		},
		{ concurrency: parallel ?? 5 },
	);
	return responses;
};
