import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import { Loader } from 'react-feather';
import Pagination from '../Component/Pagination/Pagination';
import { handleAPIRequest } from '../utils/request';
import { log } from '../utils/logger';
import { waitAll } from '../utils/promise';

import type { ReactElement } from 'react';
import type { IAbbreviatedUser } from '../Page/Patients';
import type { IBooking } from '../Page/PatientsDetail';

interface PatientsViewProps {
	data: IAbbreviatedUser[] | undefined;
	currentPage: number;
	setCurrentPage: (page: number) => void;
}
const PatientsView = ({ data, currentPage, setCurrentPage }: PatientsViewProps): ReactElement => {
	const [searchQuery, setSearchQuery] = useState<string>();
	const [searchFilter, setSearchFilter] = useState(data);

	// PAGINATION
	const patientsPerPage = 20;
	const idxLastPatient = currentPage * patientsPerPage;
	const idxFirstPatient = idxLastPatient - patientsPerPage;
	const currentPatients = searchFilter?.slice(idxFirstPatient, idxLastPatient);
	const currentPatientsIds = currentPatients?.map(patient => patient.authId) ?? [];

	const [bookings, setBookings] = useState<(IBooking[] | undefined)[]>(Array(patientsPerPage).fill(undefined));

	const getBookingsForPatient = async (authId: string, idx: number): Promise<void> => {
		const [err, res] = await handleAPIRequest<IBooking[]>({
			method: `get`,
			url: `booking/user/${authId}`,
			service: `bookings`,
		});

		if (err) {
			log.unhappy(`Patient bookings request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		setBookings(oldBookings => {
			const newBookings = [...oldBookings];
			newBookings[idx] = res.data.data;
			return newBookings;
		});
	};

	const paginate = (page: number): void => setCurrentPage(page);

	const history = useHistory();

	const filterPatients = (patients: IAbbreviatedUser[], query: string): IAbbreviatedUser[] => {
		if (!query) {
			return patients;
		}

		setCurrentPage(1);

		return patients.filter(patient => {
			const fullName = patient.fullName.toLowerCase();
			const initials = patient.initials.toLowerCase();
			const authId = patient.authId ? patient.authId.toLowerCase() : ``;
			const enterprise = patient.Patient.subscriptions[0]?.name.toLowerCase() ?? ``;
			const referralCode = patient.Patient.referralCode ? patient.Patient.referralCode.toLowerCase() : ``;
			const selectedTherapist = patient.Patient.selectedTherapist?.fullName.toLowerCase() ?? ``;

			const searchTerm = `${fullName} ${authId} ${enterprise} ${referralCode} ${initials} ${selectedTherapist}`;

			return searchTerm.includes(query);
		});
	};

	useEffect(() => {
		if (searchQuery && data) {
			setSearchFilter(filterPatients(data, searchQuery.toLowerCase()));
		}
		if (!searchQuery) setSearchFilter(data);
	}, [searchQuery, data]);

	useEffect(() => {
		const getPatientsBookings = async (): Promise<void> => {
			const tasks = currentPatientsIds.map(
				(authId, idx) => async (): Promise<void> => getBookingsForPatient(authId, idx),
			);
			await waitAll(tasks);
		};
		setBookings(Array(patientsPerPage).fill(undefined));
		void getPatientsBookings();
	}, [currentPage]);

	return (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			{/* SEARCH BOX */}
			<div className="flex justify-center">
				<div className="mb-3 xl:w-96">
					<div className="input-group relative flex flex-wrap items-stretch w-full mb-4 rounded">
						<input
							type="search"
							className="form-control relative flex-auto block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							placeholder="Search"
							aria-label="Search"
							onInput={(e): void => setSearchQuery(e.currentTarget.value)}
						/>
					</div>
				</div>
			</div>

			{/* PATIENTS TABLE || NO RESULTS */}
			{searchFilter?.length ? (
				<div>
					<table className="table-fixed rounded bg-gray-100 w-full text-center border-separate border">
						<thead className="border-b p-4">
							<tr>
								<th className="p-1 font-normal">First Name</th>
								<th className="p-1 font-normal">Last Name</th>
								<th className="p-1 font-normal">Sign Up Date</th>
								<th className="p-1 font-normal">Held Sessions</th>
								<th className="p-1 font-normal">Client</th>
								<th className="p-1 font-normal">User Subscription</th>
								<th className="p-1 font-normal">Selected Therapist</th>
							</tr>
						</thead>
						<tbody>
							{currentPatients?.map((user, idx) => {
								const subscription = user.Patient.subscriptions[0];
								const enterprise = subscription
									? subscription.name === `User subscription`
										? `-`
										: subscription.name
									: `-`;
								return (
									<tr
										className="bg-white hover:bg-gray-100 cursor-pointer"
										key={user.authId}
										onClick={(): void => history.push(`/patients/${user.authId}`)}
									>
										<td className="p-1">{user.firstName}</td>
										<td className="p-1">{user.lastName}</td>
										<td className="p-1">{formatDate(new Date(user.dateTimeCreated), `do MMM yyyy`)}</td>
										<td className="p-1">
											{bookings[idx] ? (
												// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- Substitute zero with a dash
												bookings[idx]?.filter(booking => booking.status === `HELD`).length || `-`
											) : (
												<Loader size={17} className={`stroke-current text-gray-500 animate-spin-slow mx-auto`} />
											)}
										</td>
										<td className="p-1">{enterprise}</td>
										<td className="p-1">{subscription?.type === `USER` ? `✓` : `-`}</td>
										<td className="p-1">{user.Patient.selectedTherapist?.fullName ?? `-`}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="flex justify-center mt-3">
						<p>
							Showing {currentPatients?.length} of {searchFilter.length} results.
						</p>
					</div>
					<Pagination
						pageSize={patientsPerPage}
						totalItems={searchFilter.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			) : (
				<div className="flex justify-center">
					<span className="text-gray-400">No patients to show</span>
				</div>
			)}
		</div>
	);
};

export default PatientsView;
