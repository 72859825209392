import TutorialFeed from '../Component/Feed/TutorialFeed';
import { useModifyPageTitle } from '../Hooks/hooks';

import type { ReactElement } from 'react';

const Tutorial = (): ReactElement => {
	useModifyPageTitle(`Tutorial`);

	return <TutorialFeed />;
};

export default Tutorial;
