/* eslint-disable @typescript-eslint/naming-convention -- legacy data shim format */
import { useParams, useHistory } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useState, useEffect } from 'react';
import { ArrowLeft, CheckCircle, XCircle, AlertTriangle, Download } from 'react-feather';
import { format as formatDate, subMonths } from 'date-fns';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import Button from '../Component/Button/Button';
import PatientsView from '../Component/PatientsView';
import Modal from '../Component/Modal';
import { useModifyPageTitle } from '../Hooks/hooks';
import AddSubscriptionModalContent from '../Component/ModalContent/AddSubscription';
import EditSubscriptionModalContent from '../Component/ModalContent/EditSubscription';
import EditSessionsAlertThresholdModalContent from '../Component/ModalContent/EditSessionsAlertThreshold';
import ExportBookingsModalContent from '../Component/ModalContent/ExportBookings';

import { handleAPIRequest } from '../utils/request';
import { log } from '../utils/logger';

import type { ReactElement } from 'react';
import type { IClient, ISubscription } from './Clients';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

export const clientData = atom<IClient | undefined>({
	key: `client-data`,
	default: undefined,
});

interface IParams {
	clientId: string;
}

interface IAuthUserRoles {
	PATIENT: boolean;
	THERAPIST: boolean;
	AUTHOR: boolean;
	CLIENT: boolean;
	ADMIN: boolean;
}

type IAuthUserEmailRole = `PRIMARY` | `SECONDARY`;

interface IAuthUserEmail {
	emailId: string;
	email: string;
	verified: boolean;
	type: IAuthUserEmailRole;
	authId: string;
	dateTimeCreated: string;
	dateTimeVerified: string | undefined;
}

export interface IAuthUser {
	authId: string;
	firstName: string;
	lastName: string;
	fullName: string;
	initials: string;
	accountDisabled: boolean;
	mfaEnabled: boolean;
	roles: IAuthUserRoles;
	emails: IAuthUserEmail[];
	phone: string | undefined;
	dateTimeCreated: string;
	dateTimeUpdated: string | undefined;
	temporaryPassword: boolean;
}

type IPatientSubscriptionType = `USER` | `CLIENT`;

export interface IPatientSubscription {
	type: IPatientSubscriptionType;
	name: string;
	active: boolean;
}

interface IPatientDiagnostic {
	formData: string;
	dateTimeCreated: string;
}

interface IPatientMatch {
	matchId: string;
	patientAuthId: string;
	therapistAuthId: string;
	selected: boolean;
	dateTimeCreated: string;
	confidenceScore: number;
	therapistFirstName: string;
	therapistLastName: string | undefined;
}

type IPatientBillingStatus = `NOT_BILLABLE` | `BILLABLE`;
type IPatientBookingType = `CONSULTATION` | `REGULAR` | `UNKNOWN`;

interface IPatientBooking {
	bookingId: string;
	billableSessionId: number | undefined;
	billableStatus: IPatientBillingStatus;
	bookingType: IPatientBookingType;
	selectedStartTime: string;
	selectedEndTime: string;
	therapistFirstName: string;
	therapistLastName: string | undefined;
}

interface IPatient {
	referralCode: string | undefined;
	mweInterventionComplete: boolean | undefined;
	notes: string | undefined;
	subscriptions: IPatientSubscription[];
	milestones: {
		completedDiagnostic: boolean;
		firstConsultationBooked: boolean;
		firstConsultationAttended: boolean;
		followUpSessionBooked: boolean;
		followUpSessionAttended: boolean;
	};
	diagnostics: {
		latestDiagnostic: IPatientDiagnostic | undefined;
		reportedSuicidalThoughts: boolean;
	};
	matches: IPatientMatch[];
	bookings: IPatientBooking[];
}

export interface IUser extends IAuthUser {
	gender: string | undefined;
	Patient: IPatient;
}

interface ISessionsStats {
	available: number;
	reserved: number;
	cancelled: number;
	held: number;
	missed: number;
	pending: number;
	nonBillable: number;
	total: number;
}

export interface IBookingSubscriptionStats {
	consultationSessions: ISessionsStats;
	regularSessions: ISessionsStats;
}

interface IClientUsageStats {
	patientId: number;
	firstName: string;
	lastName: string | null;
	contactNumber: string | null;
	enterpriseId: number | null;
	credentialId: number;
	created: string;
	enabled: boolean;
	completedDiagnostic: string | null;
	gender: string | null;
	completedOnboarding: string | null;
	datePhoneVerified: string | null;
	dateEmailVerified: string | null;
	enterpriseName: string | null;
	email: string;
	goalCount: string | undefined;
	sleepCount: string | undefined;
	gratitudeCount: string | undefined;
	moodCount: string | undefined;
	activityCount: string | undefined;
	completedTasks: string | undefined;
	consultationSessions: number;
	regularSessions: number;
	upcomingSessions: number;
	missedSessions: number;
	cancelledSessions: number;
	unknownSessions: number;
}

interface IClientUsageProps {
	clientId: string;
}

const ClientUsage = ({ clientId }: IClientUsageProps): ReactElement => {
	const [data, setData] = useState<IClientUsageStats[]>();
	const [loading, setLoading] = useState(false);

	const pullClientUsageStats = async (): Promise<void> => {
		setLoading(true);

		const [err, res] = await handleAPIRequest<IClientUsageStats[] | undefined>({
			method: `GET`,
			url: `reports/clients/users?enterpriseId=${clientId}`,
		});
		setLoading(false);

		if (err) {
			log.unhappy(`Client usage stats data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		if (res.status !== 200) {
			log.unhappy(`Client usage stats data pull request failed`, { error: res.status });
			return;
		}

		setData(res.data.data);
		setLoading(false);
	};

	useEffect(() => {
		void pullClientUsageStats();
	}, []);

	const femalePatients = data?.filter(patient => patient.gender === `female`) ?? [];
	const malePatients = data?.filter(patient => patient.gender === `male`) ?? [];
	const notSpecifiedPatients = data?.filter(patient => patient.gender === null) ?? [];

	const genderPieData = {
		labels: [
			`Female (${femalePatients.length})`,
			`Male (${malePatients.length})`,
			`Not specified (${notSpecifiedPatients.length})`,
		],
		datasets: [
			{
				label: `Gender`,
				data: [femalePatients.length, malePatients.length, notSpecifiedPatients.length],
				backgroundColor: [`rgba(255, 99, 132, 0.5)`, `rgba(54, 162, 235, 0.5)`, `rgba(210,210,210, 0.5)`],
			},
		],
	};

	const femaleMetrics = [
		femalePatients.reduce((acc, patient) => acc + Number(patient.goalCount), 0),
		femalePatients.reduce((acc, patient) => acc + Number(patient.sleepCount), 0),
		femalePatients.reduce((acc, patient) => acc + Number(patient.gratitudeCount), 0),
		femalePatients.reduce((acc, patient) => acc + Number(patient.moodCount), 0),
		femalePatients.reduce((acc, patient) => acc + Number(patient.activityCount), 0),
	];

	const maleMetrics = [
		malePatients.reduce((acc, patient) => acc + Number(patient.goalCount), 0),
		malePatients.reduce((acc, patient) => acc + Number(patient.sleepCount), 0),
		malePatients.reduce((acc, patient) => acc + Number(patient.gratitudeCount), 0),
		malePatients.reduce((acc, patient) => acc + Number(patient.moodCount), 0),
		malePatients.reduce((acc, patient) => acc + Number(patient.activityCount), 0),
	];

	const notSpecifiedMetrics = [
		notSpecifiedPatients.reduce((acc, patient) => acc + Number(patient.goalCount), 0),
		notSpecifiedPatients.reduce((acc, patient) => acc + Number(patient.sleepCount), 0),
		notSpecifiedPatients.reduce((acc, patient) => acc + Number(patient.gratitudeCount), 0),
		notSpecifiedPatients.reduce((acc, patient) => acc + Number(patient.moodCount), 0),
		notSpecifiedPatients.reduce((acc, patient) => acc + Number(patient.activityCount), 0),
	];

	const mfpChartData = {
		labels: [`Goal`, `Sleep`, `Gratitude`, `Mood`, `Activity`],
		datasets: [
			{
				label: `Female (${femaleMetrics.reduce((acc, metric) => acc + metric, 0)})`,
				backgroundColor: `rgba(255, 99, 132, 0.5)`,
				data: femaleMetrics,
			},
			{
				label: `Male (${maleMetrics.reduce((acc, metric) => acc + metric, 0)})`,
				backgroundColor: `rgba(54, 162, 235, 0.5)`,
				data: maleMetrics,
			},
			{
				label: `Not specified (${notSpecifiedMetrics.reduce((acc, metric) => acc + metric, 0)})`,
				backgroundColor: `rgba(210,210,210, 0.5)`,
				data: notSpecifiedMetrics,
			},
		],
	};

	return loading ? (
		<p>{data}</p>
	) : (
		<div
			className={`
				flex
				flex-col md:flex-row
		`}
		>
			<div className="md:w-3/6">
				<h5 className="font-medium mb-4">Gender breakdown</h5>
				<Pie data={genderPieData} className="max-h-48" />
			</div>
			<div className="md:w-3/6">
				<h5 className="font-medium mb-4">MFP usage</h5>
				<Bar data={mfpChartData} className="max-h-48" />
			</div>
		</div>
	);
};

interface ISessionsGraphProps {
	stats: ISessionsStats | undefined;
	isBillable: boolean;
}

const SessionsGraph = ({ stats, isBillable }: ISessionsGraphProps): ReactElement => {
	const available = isBillable ? stats?.available ?? 0 : 100;
	const reserved = stats?.reserved ?? 0;
	const heldOrCancelled = stats ? stats.held + stats.cancelled : 0;
	const missed = stats?.missed ?? 0;
	const pending = stats?.pending ?? 0;
	const total = isBillable ? stats?.total ?? 0 : 100;

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: `y` as `y`,
		scales: {
			x: {
				stacked: true,
				max: total,
			},
			y: {
				stacked: true,
			},
		},
	};

	const data = {
		labels: [`Sessions`],
		datasets: [
			{
				label: `Available`,
				data: [available],
				backgroundColor: `rgb(210, 255, 210)`,
			},
			{
				label: `Reserved`,
				data: [reserved],
				backgroundColor: `rgb(255, 204, 0)`,
			},
			{
				label: `Held or Cancelled`,
				data: [heldOrCancelled],
				backgroundColor: `rgb(120, 120, 120)`,
			},
			{
				label: `Missed`,
				data: [missed],
				backgroundColor: `rgb(205,92,92)`,
			},
			{
				label: `Pending`,
				data: [pending],
				backgroundColor: `rgb(135,206,250)`,
			},
		],
	};

	return <Bar options={options} data={data} className="max-h-24" />;
};

interface RecentMonth {
	year: number;
	month: number;
	label: string;
}

const getRecentMonths = (): RecentMonth[] => {
	const today = new Date();

	const months = [];

	for (let i = 1; i < 7; i++) {
		const date = subMonths(today, i);
		const month = date.getMonth();
		const year = date.getFullYear();

		months.push({
			year,
			month,
			label: formatDate(date, `MMMM yyyy`),
		});
	}

	return months;
};

interface ChoicePillProps {
	label: string;
	active: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ChoicePill = ({ label, active, onClick }: ChoicePillProps): ReactElement => {
	return (
		<button
			className={`
				py-1 px-3
				border border-blue-300
				rounded
				mr-2
				text-xs
				${active ? `bg-blue-300` : ``}
			`}
			onClick={onClick}
		>
			{label}
		</button>
	);
};

interface ISessionsProps {
	clientId: string;
}

const Sessions = ({ clientId }: ISessionsProps): ReactElement => {
	const client = useRecoilValue(clientData);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<IBookingSubscriptionStats>();
	const [filterIdx, setFilterIdx] = useState<number | undefined>();

	const recentMonths = getRecentMonths();

	const pullClientStats = async (): Promise<void> => {
		setLoading(true);

		let query = ``;

		if (filterIdx !== undefined && recentMonths[filterIdx]) {
			query = `?month=${recentMonths[filterIdx]?.month}&year=${recentMonths[filterIdx]?.year}`;
		}

		const [err, res] = await handleAPIRequest<IBookingSubscriptionStats | undefined>({
			method: `GET`,
			url: `client/${clientId}/stats${query}`,
			service: `bookings`,
		});
		setLoading(false);

		if (err) {
			log.unhappy(`Client data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		if (res.status !== 200) {
			log.unhappy(`Client data pull request failed`, { error: res.status });
			return;
		}

		setData(res.data.data);
	};

	// Retrigger the data pull when the filter changes or when the client data is updated:
	useEffect(() => {
		void pullClientStats();
	}, [filterIdx, client]);

	return (
		<>
			<h5 className="font-medium mb-4">Consultation Sessions</h5>
			<div className="flex justify-between flex-col md:flex-row">
				<SessionsTable
					data={data?.consultationSessions}
					loading={loading}
					isBillable={client?.billableConsultations ?? true}
				/>
				<div className="mt-4 md:w-4/6">
					<SessionsGraph stats={data?.consultationSessions} isBillable={client?.billableConsultations ?? true} />
				</div>
			</div>

			<h5 className="font-medium mb-4 mt-3">Regular Sessions</h5>
			<div className="flex justify-between flex-col md:flex-row">
				<SessionsTable data={data?.regularSessions} loading={loading} isBillable={true} />
				<div className="mt-4 md:w-4/6">
					<SessionsGraph stats={data?.regularSessions} isBillable={true} />
				</div>
			</div>
			<br />
			<div className="flex items-center">
				<p className="mr-3">Filter sessions by:</p>
				<ChoicePill label="All" active={filterIdx === undefined} onClick={(): void => setFilterIdx(undefined)} />
				{recentMonths.map((month, idx) => (
					<ChoicePill label={month.label} active={filterIdx === idx} onClick={(): void => setFilterIdx(idx)} />
				))}
			</div>
		</>
	);
};
interface SessionTableProps {
	data: ISessionsStats | undefined;
	loading: boolean;
	isBillable: boolean;
}

const SessionsTable = ({ data, loading, isBillable }: SessionTableProps): ReactElement => {
	return (
		<table className="table-auto rounded md:w-2/6 bg-gray-100 text-center border-separate border md:mr-6">
			<tbody>
				<tr className="bg-white">
					<td className="p-1">Available to use</td>
					<td className="p-1">{loading ? `...` : isBillable ? data?.available : `NB`}</td>
				</tr>
				<tr className="bg-white">
					<td className="p-1">Booked/reserved</td>
					<td className="p-1">{loading ? `...` : data?.reserved}</td>
				</tr>
				<tr className="bg-white">
					<td className="p-1">Held</td>
					<td className="p-1">{loading ? `...` : data?.held}</td>
				</tr>
				<tr className="bg-white">
					<td className="p-1">Missed</td>
					<td className="p-1">{loading ? `...` : data?.missed}</td>
				</tr>
				<tr className="bg-white">
					<td className="p-1">Pending</td>
					<td className="p-1">{loading ? `...` : data?.pending}</td>
				</tr>
				<tr className="bg-white">
					<td className="p-1">Cancelled</td>
					<td className="p-1">{loading ? `...` : data?.cancelled}</td>
				</tr>
				<tr className="bg-white">
					<td className="p-1">Non-billable</td>
					<td className="p-1">{loading ? `...` : data?.nonBillable}</td>
				</tr>
			</tbody>
		</table>
	);
};

export const ExpiredPill = (): ReactElement => (
	<div className="flex items-center justify-center rounded py-1 px-2 bg-red-100 opacity-90 ml-2">
		<p className="text-xs">Expired</p>
	</div>
);

const SessionsAlertsTable = (): ReactElement => {
	const [data] = useRecoilState(clientData);
	const [adjustSessionsModalOpen, setAdjustSessionsModalOpen] = useState(false);
	const [minConsultations, setMinConsultations] = useState(data?.consultationsThreshold ?? -1);
	const [minRegularSessions, setMinRegularSessions] = useState(data?.regularSessionsThreshold ?? -1);

	return (
		<>
			<h5 className="font-medium mb-4 float-left">Minimum Sessions Alerts</h5>
			<table className="table-auto rounded bg-gray-100 w-full text-center border-separate border">
				<thead className="border-b p-4">
					<tr>
						<th className="p-1 font-normal">Minimum Consultations</th>
						<th className="p-1 font-normal">Minimum Regular Sessions</th>
						<th className="p-1 font-normal">Options</th>
					</tr>
				</thead>
				<tbody>
					<tr className="bg-white" key={data?.id ?? `test`}>
						<td className="p-1">{minConsultations !== -1 ? minConsultations : `Not Set`}</td>
						<td className="p-1">{minRegularSessions !== -1 ? minRegularSessions : `Not Set`}</td>
						<td className="p-1 flex justify-center">
							<Button onClick={(): void => setAdjustSessionsModalOpen(true)} text="Adjust" className="my-2" />
						</td>
					</tr>
				</tbody>
			</table>
			<p>*If not set, minimum value is by default 5</p>
			{adjustSessionsModalOpen && (
				<Modal title="Adjust sessions alert threshold" onCloseClick={(): void => setAdjustSessionsModalOpen(false)}>
					<EditSessionsAlertThresholdModalContent
						clientId={data?.id ?? ``}
						uponSuccessCallback={(response: IClient): void => {
							setMinConsultations(response.consultationsThreshold);
							setMinRegularSessions(response.regularSessionsThreshold);
							setAdjustSessionsModalOpen(false);
						}}
						sessionsThreshold={{
							consultations: minConsultations,
							regular: minRegularSessions,
						}}
					/>
				</Modal>
			)}
		</>
	);
};

const SubscriptionsTable = (): ReactElement => {
	const [data] = useRecoilState(clientData);
	const [subscriptions, setSubscriptions] = useState(data?.subscriptions ?? []);
	const [adjustSubscriptionModalOpen, setAdjustSubscriptionModalOpen] = useState(false);
	const [editingSubscriptionId, setEditingSubscriptionId] = useState<string | undefined>();

	const handleAdjustment = (id: string): void => {
		setEditingSubscriptionId(id);
		setAdjustSubscriptionModalOpen(true);
	};

	const now = new Date();
	const activeSubscriptions = subscriptions.filter(subscription => new Date(subscription.dateTimeEnd) > now);
	const inactiveSubscriptions = subscriptions.filter(subscription => new Date(subscription.dateTimeEnd) < now);

	const orderedSubscriptions = [...activeSubscriptions, ...inactiveSubscriptions];

	return orderedSubscriptions.length ? (
		<>
			<table className="table-auto rounded bg-gray-100 w-full text-center border-separate border">
				<thead className="border-b p-4">
					<tr>
						<th className="p-1 font-normal">ID</th>
						<th className="p-1 font-normal">Title</th>
						<th className="p-1 font-normal">Start to End</th>
						<th className="p-1 font-normal">Consultations</th>
						<th className="p-1 font-normal">Regular Sessions</th>
						<th className="p-1 font-normal">Therapy</th>
						<th className="p-1 font-normal">MFP</th>
						<th className="p-1 font-normal">Created At</th>
						<th className="p-1 font-normal">Options</th>
					</tr>
				</thead>
				<tbody>
					{orderedSubscriptions.map(subscription => (
						<tr className="bg-white" key={subscription.id}>
							<td className="p-1">{subscription.id}</td>
							<td className="p-1 flex justify-center">
								{subscription.title} {new Date(subscription.dateTimeEnd) < new Date() && <ExpiredPill />}
							</td>
							<td className="p-1">
								{formatDate(new Date(subscription.dateTimeStart), `dd/MM/yyyy`)} to{` `}
								{formatDate(new Date(subscription.dateTimeEnd), `dd/MM/yyyy`)}
							</td>
							<td className="p-1">
								{!data?.billableConsultations
									? `NOT_BILLABLE`
									: `${subscription.consultationSessions.initialQuantity} + ${subscription.consultationSessions.additionalQuantity}`}
							</td>
							<td className="p-1">
								{subscription.regularSessions.initialQuantity} + {subscription.regularSessions.additionalQuantity}
							</td>
							<td className="p-1">
								{subscription.regularSessions.typesOfTherapy.therapy ? (
									<CheckCircle size={12} className="inline text-green-700" />
								) : (
									<XCircle size={12} className="inline text-red-700" />
								)}
							</td>
							<td className="p-1">
								{subscription.regularSessions.typesOfTherapy.mfp ? (
									<CheckCircle size={12} className="inline text-green-700" />
								) : (
									<XCircle size={12} className="inline text-red-700" />
								)}
							</td>
							<td className="p-1">
								{formatDate(new Date(subscription.dateTimeCreated ?? new Date()), `dd/MM/yyyy HH:mm`)}
							</td>
							<td className="p-1 flex justify-center">
								<Button onClick={(): void => handleAdjustment(subscription.id ?? ``)} text="Adjust" className="my-2" />
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{adjustSubscriptionModalOpen && (
				<Modal title="Adjust subscription" onCloseClick={(): void => setAdjustSubscriptionModalOpen(false)}>
					<EditSubscriptionModalContent
						uponSuccessCallback={(response): void => {
							const updatedSubscriptions = subscriptions.map(subscription => {
								return subscription.id === response.id ? response : subscription;
							});
							setSubscriptions(updatedSubscriptions);
							setAdjustSubscriptionModalOpen(false);
						}}
						subscription={
							subscriptions.find(subscription => subscription.id === editingSubscriptionId) as ISubscription
						}
					/>
				</Modal>
			)}
		</>
	) : (
		<span className="text-gray-400">No subscriptions to show</span>
	);
};

interface DetailsProps {
	data: IClient;
}

const Details = ({
	data: {
		name,
		address: { firstLine, secondLine, city, postcode },
		contactNumber,
		dateTimeCreated,
	},
}: DetailsProps): ReactElement => {
	return (
		<div className="flex justify-between">
			<div>
				<h2 className="text-2xl">{name}</h2>
				<div className="mt-4">
					{firstLine}
					{secondLine && `, ${secondLine}`}, {city}, {postcode}
					<br />
					Tel:&nbsp;
					{contactNumber ? (
						<a href={`tel:${contactNumber}`} className="custom-explicit-link">
							{contactNumber}
						</a>
					) : (
						<p className="inline text-gray-400">n/a</p>
					)}
				</div>
			</div>
			<div className="text-right">
				Created:&nbsp;
				<span>{formatDate(new Date(dateTimeCreated), `do MMM yyyy`)}</span>
			</div>
		</div>
	);
};

interface EditClientModalContentProps {
	uponSuccessCallback: () => void;
}

const EditClientModalContent = ({ uponSuccessCallback }: EditClientModalContentProps): ReactElement => {
	const [data, setClientData] = useRecoilState(clientData);
	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [errored, setErrored] = useState(false);
	const [contactNumber, setContactNumber] = useState(data?.contactNumber);

	const finishedUpdate = !loading && !errored && completed;
	const changed = contactNumber !== data?.contactNumber;

	if (finishedUpdate) {
		uponSuccessCallback();
	}

	const updateClient = async (): Promise<void> => {
		if (!data) {
			return;
		}

		const reqBody = {
			client: {
				contactNumber,
			},
		};

		setErrored(false);
		setLoading(true);
		const [err, res] = await handleAPIRequest<IClient>({
			method: `PUT`,
			url: `clients/${data.id}`,
			reqBody,
		});
		setLoading(false);

		if (err || res?.status !== 200) {
			setErrored(true);
			setCompleted(true);
			return;
		}

		setCompleted(true);
		setClientData(res.data.data);
	};

	const handleContactNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setContactNumber(e.target.value);
	};

	return (
		<>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="flex items-center">
				<span className="text-sm flex-shrink-0 mr-3">Tel:</span>
				<input
					type="text"
					className="w-auto py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={contactNumber}
					onChange={handleContactNumberChange}
				/>
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={updateClient}
				disabled={!changed}
				text="Update"
			/>
		</>
	);
};

const NoActiveSubscriptionsWarning = (): ReactElement => {
	return (
		<div
			className={`
			flex items-center justify-center
			bg-red-50
			text-xs
			border border-red-200
			mb-2
			w-full
			py-2
			rounded
		`}
		>
			<AlertTriangle size={14} className="mr-2" />
			Note that all subscriptions have expired. Any remaining sessions will not be able to be used.
		</div>
	);
};

const Notes = (): ReactElement => {
	const [data, setClientData] = useRecoilState(clientData);
	const [loading, setLoading] = useState(false);
	const [errored, setErrored] = useState(false);
	const [notes, setNotes] = useState(data?.notes ?? ``);

	const textLines = notes.split(/\r?\n/).length;
	const rows = textLines > 3 ? textLines : 3;

	const changed = notes !== (data?.notes ?? ``);

	const updateClientNotes = async (): Promise<void> => {
		if (!data) {
			return;
		}
		const reqBody = { client: { notes } };
		setErrored(false);
		setLoading(true);
		const [err, res] = await handleAPIRequest<IClient>({
			method: `PUT`,
			url: `clients/${data.id}`,
			reqBody,
		});
		setLoading(false);

		if (err || res?.status !== 200) {
			setErrored(true);
			return;
		}
		setClientData(res.data.data);
	};

	return (
		<>
			<h5 className="font-medium mb-4 float-left">Notes</h5>
			<Button
				className={`float-right`}
				onClick={async (): Promise<void> => updateClientNotes()}
				loading={loading}
				disabled={!changed}
				text={`Save Notes`}
			/>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<textarea
				className={`
					block
					form-control
					w-full
					px-3 py-1.5
					m-0
					text-sm text-gray-700
					font-normal
					bg-yellow-100 bg-clip-padding
					border border-solid border-gray-300
					rounded
					transition
					ease-in-out
					focus:text-gray-700 focus:border-amber-300 focus:outline-none
				`}
				rows={rows}
				placeholder="Enter notes about the user here"
				value={notes}
				disabled={loading}
				onChange={(e): void => setNotes(e.target.value)}
			></textarea>
		</>
	);
};

interface ContentProps {
	data: IClient;
}

const Content = ({ data }: ContentProps): ReactElement => {
	const setClientData = useSetRecoilState(clientData);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [exportBookingsModalOpen, setExportBookingsModalOpen] = useState(false);
	const [addSubscriptionModalOpen, setAddSubscriptionModalOpen] = useState(false);
	const [patientsPageNumber, setPatientsPageNumber] = useState(1);
	const [clientUsageStatsLoading, setClientUsageStatsLoading] = useState(false);
	const history = useHistory();

	const now = new Date();
	const activeSubscriptions = data.subscriptions.filter(subscription => new Date(subscription.dateTimeEnd) > now);
	const inactiveSubscriptions = data.subscriptions.filter(subscription => new Date(subscription.dateTimeEnd) < now);

	const downloadClientUsageStats = async (): Promise<void> => {
		setClientUsageStatsLoading(true);

		const [err, res] = await handleAPIRequest<IClientUsageStats[] | undefined>({
			method: `GET`,
			url: `reports/clients/users?enterpriseId=${data.id}`,
		});
		setClientUsageStatsLoading(false);

		if (err) {
			log.unhappy(`Client usage stats data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		if (res.status !== 200) {
			log.unhappy(`Client usage stats data pull request failed`, { error: res.status });
			return;
		}

		if (!res.data.data?.[0]) {
			return;
		}

		// Convert to CSV:
		let csv = `data:text/csv;charset=utf-8,`;
		const header = Object.keys(res.data.data[0]).join(`,`);
		const values = res.data.data.map(o => Object.values(o).join(`,`)).join(`\n`);

		csv += header + `\n` + values;

		// Trigger download:
		const encodedUri = encodeURI(csv);
		const link = document.createElement(`a`);
		link.setAttribute(`href`, encodedUri);
		link.setAttribute(`download`, `client_usage_stats_for_client_${data.id}.csv`);
		document.body.appendChild(link);
		link.click();

		setClientUsageStatsLoading(false);
	};

	return (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			<div
				className={`
			flex
			flex-row
			justify-between
			`}
			>
				<button
					onClick={history.goBack}
					className={`
					custom-explicit-link
					text-xs
					flex items-center
				`}
				>
					<ArrowLeft size={12} className="mr-1" />
					Go back
				</button>
				<div
					className={`
						flex
						flex-row
					`}
				>
					<Button
						className="mr-2"
						loading={false}
						text={`Export Booking Stats Data`}
						onClick={(): void => setExportBookingsModalOpen(true)}
					/>
					<Button
						className="mr-2"
						loading={clientUsageStatsLoading}
						text={
							<div className="flex items-center justify-center">
								<Download size={14} />
								<span className="ml-2">Export Usage Data</span>
							</div>
						}
						onClick={(): void => void downloadClientUsageStats()}
					/>
					<Button loading={false} text={`Edit`} onClick={(): void => setEditModalOpen(true)} />
				</div>
			</div>
			<hr className="mt-8 mb-8" />
			<Details data={data} />
			<hr className="mt-8 mb-8" />
			<Sessions clientId={data.id} />
			<hr className="mt-8 mb-4" />
			<div className="flex items-center justify-between mb-4">
				<h5 className="font-medium inline">Subscriptions</h5>
				<Button
					className={`mt-6 mb-1`}
					variant="system"
					text="+ New"
					onClick={(): void => setAddSubscriptionModalOpen(true)}
				/>
			</div>
			{!activeSubscriptions.length && inactiveSubscriptions.length ? <NoActiveSubscriptionsWarning /> : <></>}
			<SubscriptionsTable />
			<hr className="mt-8 mb-8" />
			<SessionsAlertsTable />
			<hr className="mt-8 mb-8" />
			<Notes />
			<hr className="mt-8 mb-8" />
			<ClientUsage clientId={data.id} />
			<hr className="mt-8 mb-8" />
			<h5 className="font-medium mb-4">Patients</h5>
			<PatientsView data={data.patients} currentPage={patientsPageNumber} setCurrentPage={setPatientsPageNumber} />
			{editModalOpen && (
				<Modal title="Edit client" onCloseClick={(): void => setEditModalOpen(false)}>
					<EditClientModalContent uponSuccessCallback={(): void => setEditModalOpen(false)} />
				</Modal>
			)}
			{addSubscriptionModalOpen && (
				<Modal title="Add subscription" onCloseClick={(): void => setAddSubscriptionModalOpen(false)}>
					<AddSubscriptionModalContent
						uponSuccessCallback={(response: ISubscription): void => {
							const newClientSubscriptions = [...data.subscriptions, response];
							const newClientData = {
								...data,
								subscriptions: newClientSubscriptions,
							};

							setClientData(newClientData);
							setAddSubscriptionModalOpen(false);
						}}
						entity="CLIENT"
						entityId={data.id}
					/>
				</Modal>
			)}
			{exportBookingsModalOpen && (
				<Modal title="Export Client Bookings" onCloseClick={(): void => setExportBookingsModalOpen(false)}>
					<ExportBookingsModalContent
						enterpriseId={data.id}
						uponSuccessCallback={(): void => setExportBookingsModalOpen(false)}
					/>
				</Modal>
			)}
		</div>
	);
};

export const Loading = (): ReactElement => {
	return (
		<div
			className={`
				flex items-center justify-center
				custom-h-full
			`}
		>
			<p className="font-extralight">Loading...</p>
		</div>
	);
};

const Page = (): ReactElement => {
	const { clientId } = useParams<IParams>();
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useRecoilState(clientData);
	const resetData = useResetRecoilState(clientData);

	const pullClientData = async (): Promise<void> => {
		setLoading(true);
		const [err, res] = await handleAPIRequest<IClient | undefined>({ method: `get`, url: `clients/${clientId}` });
		setLoading(false);

		if (err) {
			log.unhappy(`Client data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		setData(res.data.data);
	};

	useModifyPageTitle(data?.name);

	useEffect(() => {
		resetData();
		void pullClientData();
	}, []);

	return loading || !data ? <Loading /> : <Content data={data} />;
};

export default Page;
/* eslint-enable @typescript-eslint/naming-convention -- legacy data shim format */
