import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { format as formatDate } from 'date-fns';
import { handleAPIRequest } from '../utils/request';
import { log } from '../utils/logger';
import { Loading } from './PatientsDetail';
import Pagination from '../Component/Pagination/Pagination';
import Button from '../Component/Button/Button';
import Modal from '../Component/Modal';
import { useModifyPageTitle } from '../Hooks/hooks';
import { waitAll } from '../utils/promise';

import type { IAuthUser, IPatientSubscription } from './PatientsDetail';
import type { ReactElement } from 'react';
import type { IBookingSubscriptionStats } from './ClientsDetail';

const currentPageNumber = atom<number>({
	key: `current-clients-page-number`,
	default: 1,
});

const clientList = atom<IClient[]>({
	key: `client-list`,
	default: [],
});

interface ISelectedTherapist {
	authId: string;
	fullName: string;
}

interface IAbbreviatedUser extends IAuthUser {
	Patient: {
		referralCode: undefined | string;
		subscriptions: IPatientSubscription[];
		numberOfHeldSessions: number;
		selectedTherapist: ISelectedTherapist | undefined;
	};
}

interface IClientAddress {
	firstLine: string;
	secondLine: string | undefined;
	city: string;
	postcode: string;
}

interface ITypesOfTherapy {
	therapy: boolean;
	coaching: boolean;
	mfp: boolean;
}

interface ISubscriptionSessions {
	initialQuantity: number;
	additionalQuantity: number;
	typesOfTherapy: ITypesOfTherapy;
}

export interface IPersonalSubscriptionKind {
	type: `PERSONAL`;
	patientAuthId: string;
}

export interface IClientSubscriptionKind {
	type: `CLIENT`;
	clientId: string;
}

export interface ISubscription {
	id?: string;
	title: string;
	kind: IPersonalSubscriptionKind | IClientSubscriptionKind;
	consultationSessions: ISubscriptionSessions;
	regularSessions: ISubscriptionSessions;
	dateTimeStart: string;
	dateTimeEnd: string;
	dateTimeCreated?: string;
}

export interface IClientSessions {
	consultations: ISessionBreakdown;
	regular: ISessionBreakdown;
}

export interface ISessionBreakdown {
	available: number;
	used: {
		held: number;
		cancelled: number;
		expired: number;
	};
}

export interface IClient {
	id: string;
	name: string;
	label: string | undefined;
	address: IClientAddress;
	contactNumber: string;
	subscriptions: ISubscription[];
	sessions: IClientSessions;
	availableConsultationSessions: number;
	availableRegularSessions: number;
	patients: IAbbreviatedUser[];
	dateTimeCreated: string;
	billableConsultations: boolean;
	billableConsultationsFrom?: Date;
	notes?: string;
	consultationsThreshold: number;
	regularSessionsThreshold: number;
}

interface IClientRequest {
	name: string;
	address: IClientAddress;
	contactNumber: string;
	billableConsultations: boolean;
	billableConsultationsFrom?: Date;
	notes?: string;
}

const newClientTemplateObj: IClientRequest = {
	name: ``,
	address: {
		firstLine: ``,
		secondLine: ``,
		city: ``,
		postcode: ``,
	},
	contactNumber: ``,
	billableConsultations: true,
	billableConsultationsFrom: new Date(),
	notes: ``,
};

const ClientsTable = (): ReactElement => {
	const data = useRecoilValue(clientList);
	const [currentPage, setCurrentPage] = useRecoilState(currentPageNumber);
	const [searchQuery, setSearchQuery] = useState<string>();
	const [searchFilter, setSearchFilter] = useState(data);

	// PAGINATION
	const clientsPerPage = 20;
	const idxLastClient = currentPage * clientsPerPage;
	const idxFirstClient = idxLastClient - clientsPerPage;
	const currentClients = searchFilter.slice(idxFirstClient, idxLastClient);

	const paginate = (page: number): void => setCurrentPage(page);

	const history = useHistory();

	const filterClients = (clients: IClient[], query: string): IClient[] => {
		if (!query) {
			return clients;
		}

		setCurrentPage(1);

		return clients.filter(client => {
			const name = client.name.toLowerCase();

			const searchTerm = `${name}`;

			return searchTerm.includes(query);
		});
	};

	useEffect(() => {
		if (searchQuery) {
			setSearchFilter(filterClients(data, searchQuery.toLowerCase()));
		}
		if (!searchQuery) setSearchFilter(data);
	}, [searchQuery, data]);

	return (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			{/* SEARCH BOX */}
			<div className="flex justify-center">
				<div className="mb-3 xl:w-96">
					<div className="input-group relative flex flex-wrap items-stretch w-full mb-4 rounded">
						<input
							type="search"
							className="form-control relative flex-auto block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							placeholder="Search"
							aria-label="Search"
							onInput={(e): void => setSearchQuery(e.currentTarget.value)}
						/>
					</div>
				</div>
			</div>

			{/* CLIENTS TABLE || NO RESULTS */}
			{searchFilter.length ? (
				<div>
					<table className="table-fixed rounded bg-gray-100 w-full text-center border-separate border">
						<thead className="border-b p-4">
							<tr>
								<th className="p-1 font-normal">Name</th>
								<th className="p-1 font-normal">Active Subscription</th>
								<th className="p-1 font-normal">Remaining Consultations</th>
								<th className="p-1 font-normal">Remaining Regular Sessions</th>
								<th className="p-1 font-normal">Number of Patients</th>
								<th className="p-1 font-normal">Created At</th>
								<th className="p-1 font-normal">Billable Consultations</th>
								<th className="p-1 font-normal">Billable Consultations From</th>
							</tr>
						</thead>
						<tbody>
							{currentClients.map(client => {
								const activeSubscriptions = client.subscriptions.filter(subscription => {
									return new Date(subscription.dateTimeEnd) > new Date();
								});

								return (
									<tr
										className="bg-white hover:bg-gray-100 cursor-pointer"
										key={client.id}
										onClick={(): void => history.push(`/clients/${client.id}`)}
									>
										<td className="p-1">{client.name}</td>
										<td className="p-1">{activeSubscriptions.length > 0 ? `TRUE` : `FALSE`}</td>
										<td className="p-1">
											{client.billableConsultations ? client.availableConsultationSessions : `NOT_BILLABLE`}
										</td>
										<td className="p-1">{client.availableRegularSessions}</td>
										<td className="p-1">{client.patients.length}</td>
										<td className="p-1">{formatDate(new Date(client.dateTimeCreated), `do MMM yyyy`)}</td>
										<td className="p-1">{client.billableConsultations ? `TRUE` : `FALSE`}</td>
										<td className="p-1">
											{client.billableConsultationsFrom
												? formatDate(new Date(client.billableConsultationsFrom), `do MMM yyyy`)
												: ``}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="flex justify-center mt-3">
						<p>
							Showing {currentClients.length} of {searchFilter.length} results.
						</p>
					</div>
					<Pagination
						pageSize={clientsPerPage}
						totalItems={searchFilter.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			) : (
				<div className="flex justify-center">
					<span className="text-gray-400">No clients to show</span>
				</div>
			)}
		</div>
	);
};

interface AddNewClientModalOpenContentProps {
	uponSuccessCallback: () => void;
}

const AddNewClientModalOpenContent = ({ uponSuccessCallback }: AddNewClientModalOpenContentProps): ReactElement => {
	const [data, setClientList] = useRecoilState(clientList);
	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [errored, setErrored] = useState(false);
	const [newClientData, setNewClientData] = useState<IClientRequest>(newClientTemplateObj);

	const finishedRequest = !loading && !errored && completed;
	const postcodeRegex = /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
	const submittable =
		newClientData.name &&
		newClientData.contactNumber &&
		newClientData.address.firstLine &&
		newClientData.address.city &&
		postcodeRegex.test(newClientData.address.postcode);

	if (finishedRequest) {
		uponSuccessCallback();
	}

	const postClient = async (): Promise<void> => {
		setErrored(false);
		setLoading(true);

		const [err, res] = await handleAPIRequest<IClient>({
			method: `POST`,
			url: `clients`,
			reqBody: {
				client: newClientData,
			},
		});
		setLoading(false);

		if (err || res?.status !== 200) {
			setErrored(true);
			setCompleted(true);
			return;
		}

		setCompleted(true);
		setClientList([...data, res.data.data]);
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNewClientData({ ...newClientData, name: e.currentTarget.value });
	};

	const handleAddressFirstLineChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNewClientData({ ...newClientData, address: { ...newClientData.address, firstLine: e.currentTarget.value } });
	};

	const handleAddressSecondLineChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNewClientData({ ...newClientData, address: { ...newClientData.address, secondLine: e.currentTarget.value } });
	};

	const handleAddressCityChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNewClientData({ ...newClientData, address: { ...newClientData.address, city: e.currentTarget.value } });
	};

	const handleAddressPostcodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNewClientData({ ...newClientData, address: { ...newClientData.address, postcode: e.currentTarget.value } });
	};

	const handleContactNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNewClientData({ ...newClientData, contactNumber: e.target.value });
	};

	const handleBillableConsultationsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const isBillable = e.target.checked;
		setNewClientData({
			...newClientData,
			billableConsultations: isBillable,
			billableConsultationsFrom: isBillable ? new Date() : undefined,
		});
	};

	return (
		<>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Name:</span>
				<input
					type="text"
					className="w-auto py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={newClientData.name}
					onChange={handleNameChange}
				/>
			</div>
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Tel:</span>
				<input
					type="text"
					className="w-48 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={newClientData.contactNumber}
					onChange={handleContactNumberChange}
				/>
			</div>
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Billable Consultations:</span>
				<input
					type="checkbox"
					className="py-1 px-2 inline-block rounded border-gray-400"
					checked={newClientData.billableConsultations}
					onChange={handleBillableConsultationsChange}
				/>
			</div>
			<hr className="mt-3 mb-3 h-1 border-gray-500 w-full border-gray-200" />
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Address Line 1:</span>
				<input
					type="text"
					className="w-auto py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={newClientData.address.firstLine}
					onChange={handleAddressFirstLineChange}
				/>
			</div>
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Address Line 2:</span>
				<input
					type="text"
					className="w-auto py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={newClientData.address.secondLine}
					onChange={handleAddressSecondLineChange}
				/>
			</div>
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">City:</span>
				<input
					type="text"
					className="w-auto py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={newClientData.address.city}
					onChange={handleAddressCityChange}
				/>
			</div>
			<div className="flex items-center">
				<span className="text-sm flex-shrink-0 mr-3">Postcode:</span>
				<input
					type="text"
					className="w-24 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={newClientData.address.postcode}
					onChange={handleAddressPostcodeChange}
					maxLength={8}
				/>
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={postClient}
				disabled={!submittable}
				text={`Add ${newClientData.name}`}
			/>
		</>
	);
};

const Page = (): ReactElement => {
	const [loading, setLoading] = useState<boolean>(false);
	const setData = useSetRecoilState(clientList);
	const [addNewClientModalOpen, setAddNewClientModalOpen] = useState(false);

	const pullClientsData = async (): Promise<void> => {
		setLoading(true);
		const [err, res] = await handleAPIRequest<IClient[] | undefined>({ method: `get`, url: `clients` });

		if (err || !res?.data.data) {
			log.unhappy(`Client data pull request failed`, { error: err });
			return;
		}

		const clients = res.data.data;

		const tasks = clients.map(client => async (): Promise<IClient> => {
			const clientStats = await pullClientStats(client.id);
			const availableConsultationSessions = clientStats?.consultationSessions.available ?? 0;
			const availableRegularSessions = clientStats?.regularSessions.available ?? 0;
			return { ...client, availableConsultationSessions, availableRegularSessions };
		});
		const clientsResult = await waitAll(tasks, 5);

		setData(clientsResult);
		setLoading(false);
	};

	const pullClientStats = async (clientId: string): Promise<IBookingSubscriptionStats | undefined> => {
		const [err, res] = await handleAPIRequest<IBookingSubscriptionStats | undefined>({
			method: `GET`,
			url: `client/${clientId}/stats`,
			service: `bookings`,
		});

		if (err) {
			log.unhappy(`Client data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		if (res.status !== 200) {
			log.unhappy(`Client data pull request failed`, { error: res.status });
			return;
		}

		return res.data.data as IBookingSubscriptionStats;
	};

	useModifyPageTitle(`Clients`);

	useEffect(() => {
		void pullClientsData();
	}, []);

	return loading ? (
		<Loading />
	) : (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			<div
				className={`
			flex
			flex-row
			justify-between
			`}
			>
				<span className="text-2xl">All Clients</span>
				<div
					className={`
						flex
						flex-row
					`}
				>
					<Button loading={false} text={`New`} onClick={(): void => setAddNewClientModalOpen(true)} />
				</div>
			</div>
			<hr className="mt-6 mb-6" />
			<ClientsTable />
			{addNewClientModalOpen && (
				<Modal title="New client" onCloseClick={(): void => setAddNewClientModalOpen(false)}>
					<AddNewClientModalOpenContent uponSuccessCallback={(): void => setAddNewClientModalOpen(false)} />
				</Modal>
			)}
		</div>
	);
};

export default Page;
