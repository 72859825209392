import { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { handleAPIRequest } from '../utils/request';
import { log } from '../utils/logger';
import { Loading } from './PatientsDetail';
import PatientsView from '../Component/PatientsView';
import { useModifyPageTitle } from '../Hooks/hooks';
import Button from '../Component/Button/Button';
import ExportBookingsModalContent from '../Component/ModalContent/ExportBookings';
import Modal from '../Component/Modal';

import type { IAuthUser, IPatientSubscription } from './PatientsDetail';
import type { ReactElement } from 'react';

const currentPageNumber = atom<number>({
	key: `current-patients-page-number`,
	default: 1,
});

interface ISelectedTherapist {
	authId: string;
	fullName: string;
}

export interface IAbbreviatedUser extends IAuthUser {
	Patient: {
		referralCode: undefined | string;
		subscriptions: IPatientSubscription[];
		numberOfHeldSessions: number;
		selectedTherapist: ISelectedTherapist | undefined;
	};
}

const Page = (): ReactElement => {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<IAbbreviatedUser[] | undefined>();
	const [currentPage, setCurrentPage] = useRecoilState(currentPageNumber);
	const [exportBookingsModalOpen, setExportBookingsModalOpen] = useState(false);

	const pullPatientData = async (): Promise<void> => {
		setLoading(true);
		const [err, res] = await handleAPIRequest<IAbbreviatedUser[] | undefined>({ method: `get`, url: `users` });
		setLoading(false);

		if (err) {
			log.unhappy(`Patient data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		setData(res.data.data);
	};

	useModifyPageTitle(`Patients`);

	useEffect(() => {
		void pullPatientData();
	}, []);

	return loading || !data ? (
		<Loading />
	) : (
		<>
			<div className={`flex flex-row justify-end py-2 px-4`}>
				<Button
					className="mr-2"
					loading={false}
					text={`Export Booking Stats Data`}
					onClick={(): void => setExportBookingsModalOpen(true)}
				/>
			</div>
			<PatientsView data={data} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			{exportBookingsModalOpen && (
				<Modal title="Export Client Bookings" onCloseClick={(): void => setExportBookingsModalOpen(false)}>
					<ExportBookingsModalContent uponSuccessCallback={(): void => setExportBookingsModalOpen(false)} />
				</Modal>
			)}
		</>
	);
};

export default Page;
