import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById(`root`),
);

// If you want to start measuring performance in your app
// send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals(undefined);
