import { Redirect, Route } from 'react-router-dom';

import { isAuthenticated } from '../utils/auth';

import type { ReactElement } from 'react';
import type { RouteProps } from 'react-router-dom';

const ProtectedRoute = (routeProps: RouteProps): ReactElement => {
	if (!isAuthenticated()) {
		return <Redirect to="/login" />;
	}
	return <Route {...routeProps} />;
};

export default ProtectedRoute;
