import { useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { Redirect } from 'react-router-dom';

import AlertList from '../Alert/AlertList';
import Alert from '../Alert/Alert';
import { getCurrentDateTime } from '../../utils/date';

import type { ReactElement } from 'react';
import type { IAlert } from '../Alert/Alert';

const tutorialAlerts: IAlert[] = [
	{
		alertId: `one`,
		text: {
			title: `Welcome to the new internal dashboard. We've got a few things to show you.`,
		},
		dateTimeCreated: getCurrentDateTime(),
		actions: [
			{
				id: `one`,
				text: `Next`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `two`,
		text: {
			title: `This is an alert. This area here will tell you what has just happened.`,
		},
		actions: [
			{
				id: `two`,
				text: `Next`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `three`,
		status: `NEW`,
		text: {
			title: `This is a new alert, as you can see from the blue dot. This means you haven't seen it before.`,
		},
		actions: [
			{
				id: `three`,
				text: `Next`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `four`,
		text: {
			title: `A green button on an alert lets you trigger an action seamlessly and automatically.`,
		},
		actions: [
			{
				id: `basic-loading`,
				type: `primary`,
				text: `Do Something`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `five`,
		priority: `HIGH`,
		text: {
			title: `Sometimes, an urgent alert will pop up. It will go straight to the top and be in red.`,
		},
		actions: [
			{
				id: `four`,
				text: `Next`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `six`,
		dismissable: true,
		text: {
			title: `From time to time, you might want to dismiss an alert. No action will be taken.`,
		},
		actions: [],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `seven`,
		text: {
			title: `Often, the necessary action will use Intercom. A blue button can facilitate this.`,
		},
		actions: [
			{
				id: `intercom-example`,
				type: `secondary`,
				text: `Open a Conversation`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `eight`,
		text: {
			title: `There might be more than one option available.`,
		},
		actions: [
			{
				id: `five`,
				type: `primary`,
				text: `An automated one`,
			},
			{
				id: `six`,
				type: `secondary`,
				text: `An Intercom one`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `nine`,
		text: {
			title: `Old alerts will automatically be hidden.`,
		},
		actions: [
			{
				id: `clear-alerts`,
				text: `Next`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
	{
		alertId: `ten`,
		text: {
			title: `That's everything. You'll start receiving alerts now. Let the development team know if you have any issues.`,
		},
		actions: [
			{
				id: `complete`,
				text: `Complete`,
			},
		],
		tagged: false,
		dottable: true,
		tags: [],
	},
];

const tutorialLoadingExampleAtom = atom({
	key: `tutorial-loading-example-atom`,
	default: false,
});

const tutorialIntercomLoadingExampleAtom = atom({
	key: `tutorial-intercom-loading-example-atom`,
	default: false,
});

const Tutorial = (): ReactElement => {
	const [alerts, setAlerts] = useState([tutorialAlerts[0]]);
	const [basicLoading, setBasicLoading] = useRecoilState(tutorialLoadingExampleAtom);
	const [intercomExample, setIntercomExample] = useRecoilState(tutorialIntercomLoadingExampleAtom);
	const [finished, setFinished] = useState(false);

	const advance = (): void => {
		const existingAlerts = alerts.map(alert => ({ ...alert, disabled: true }));
		setAlerts([
			{ ...(tutorialAlerts[alerts.length] as IAlert), dateTimeCreated: getCurrentDateTime() },
			...(existingAlerts as IAlert[]),
		]);
	};

	const loadingExample = (): void => {
		setBasicLoading(true);
		setTimeout(() => {
			setBasicLoading(false);
			advance();
		}, 1500);
	};

	const intercomLoadingExample = (): void => {
		setIntercomExample(true);
		setTimeout(() => {
			setIntercomExample(false);
			// Load modal here
			advance();
		}, 1000);
	};

	const clearAlerts = (): void => {
		const pointer = alerts.length;
		advance();
		setTimeout(() => {
			setAlerts([tutorialAlerts[pointer]]);
		}, 50);
	};

	const complete = (): void => {
		setAlerts([]);
		setTimeout(() => {
			setFinished(true);
		}, 1500);
	};

	const hydrateOnClick = (id: string): (() => void) => {
		if (id === `basic-loading`) {
			return loadingExample;
		}

		if (id === `intercom-example`) {
			return intercomLoadingExample;
		}

		if (id === `clear-alerts`) {
			return clearAlerts;
		}

		if (id === `complete`) {
			return complete;
		}

		return advance;
	};

	return (
		<>
			{finished && <Redirect to="/alerts" />}
			<AlertList>
				{alerts.map(
					a =>
						a && (
							<Alert
								alertId={a.alertId}
								key={a.alertId}
								text={a.text}
								disabled={a.disabled}
								status={a.status}
								tagged={a.tagged}
								priority={a.priority}
								dismissable={a.dismissable}
								dottable={a.dottable}
								dateTimeCreated={a.dateTimeCreated}
								onDismiss={advance}
								tags={a.tags}
								actions={a.actions.map(action => ({
									...action,
									onClick: hydrateOnClick(action.id),
									loading:
										action.id === `basic-loading`
											? basicLoading
											: action.id === `intercom-example`
											? intercomExample
											: false,
								}))}
							/>
						),
				)}
			</AlertList>
		</>
	);
};

export default Tutorial;
