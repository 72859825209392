/* eslint-disable @typescript-eslint/naming-convention -- legacy data shim format */
import { format as formatDate } from 'date-fns';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { AlertTriangle, ArrowLeft, CheckCircle, XCircle } from 'react-feather';
import ReactMarkdown from 'react-markdown';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../Component/Button/Button';
import { log } from '../utils/logger';
import { handleAPIRequest } from '../utils/request';
import { ExpiredPill } from './ClientsDetail';
import Modal from '../Component/Modal';
import EditSubscriptionModalContent from '../Component/ModalContent/EditSubscription';
import AddSubscriptionModalContent from '../Component/ModalContent/AddSubscription';
import { useModifyPageTitle } from '../Hooks/hooks';
import AddNewMatchModalContent from '../Component/ModalContent/AddNewMatch';
import EditMatchModalContent from '../Component/ModalContent/EditMatch';

import type { ReactElement } from 'react';
import type { HandleAPIRequestError, IHandleAPIRequestResponse } from '../utils/request';
import type { IClient, IClientSubscriptionKind, ISubscription } from './Clients';

const userData = atom<IUser | undefined>({
	key: `user-data`,
	default: undefined,
});
interface IParams {
	authId: string;
}

interface IAuthUserRoles {
	PATIENT: boolean;
	THERAPIST: boolean;
	AUTHOR: boolean;
	CLIENT: boolean;
	ADMIN: boolean;
}

type IAuthUserEmailRole = `PRIMARY` | `SECONDARY`;

interface IAuthUserEmail {
	emailId: string;
	email: string;
	verified: boolean;
	type: IAuthUserEmailRole;
	authId: string;
	dateTimeCreated: string;
	dateTimeVerified: string | undefined;
}

export interface IAuthUser {
	authId: string;
	firstName: string;
	lastName: string;
	fullName: string;
	initials: string;
	accountDisabled: boolean;
	mfaEnabled: boolean;
	roles: IAuthUserRoles;
	emails: IAuthUserEmail[];
	phone: string | undefined;
	dateTimeCreated: string;
	dateTimeUpdated: string | undefined;
	temporaryPassword: boolean;
}

type IPatientSubscriptionType = `USER` | `CLIENT`;

export interface IPatientSubscription {
	type: IPatientSubscriptionType;
	name: string;
	active: boolean;
}

interface IPatientDiagnostic {
	formData: string;
	dateTimeCreated: string;
}

export interface IPatientMatch {
	matchId: string;
	patientAuthId: string;
	therapistAuthId: string;
	selected: boolean;
	dateTimeCreated: string;
	confidenceScore: number;
	therapistFirstName: string;
	therapistLastName: string | undefined;
}

type BillingStatus = `BILLABLE` | `NOT_BILLABLE`;

type IPatientBillingStatus = `NOT_BILLABLE` | `BILLABLE`;

interface IBookingParticipant {
	authId: string;
	firstName: string;
	lastName: string | undefined;
	patientId?: string;
	therapistId?: string;
	contactNumber?: string | undefined;
}

interface IBookingSlot {
	startDateTimeUTC: string;
	startDateTimeLocal: string;
	lengthMins: number;
}

export type APIBillingStatus = BillingStatus | `UNKNOWN`;

interface IBookingBilling {
	status: APIBillingStatus;
	changeReason: string | undefined;
}

type BookingLocation = `IN_APP_VIDEO` | `ZOOM`;

interface IBookingLocation {
	location: BookingLocation;
	changeReason: string | undefined;
	twilioRoomSid: string | undefined;
	twilioPatientParticipantId: string | undefined;
	twilioTherapistParticipantId: string | undefined;
}

type BookingType = `CONSULTATION` | `REGULAR`;

type APIBookingType = BookingType | `UNKNOWN`;

type BookingStatus = `UPCOMING` | `HELD` | `MISSED` | `CANCELLED` | `PENDING` | `REJECTED`;

type BookingParticipant = `PATIENT` | `THERAPIST`;

export interface IBooking {
	bookingId: string;
	subscriptionId: string | undefined;
	type: APIBookingType;
	status: BookingStatus;
	patient: IBookingParticipant;
	therapist: IBookingParticipant;
	slot: IBookingSlot;
	billing: IBookingBilling;
	location: IBookingLocation;
	cancelledBy: BookingParticipant | undefined;
	missedBy: BookingParticipant | undefined;
	twilioPatientParticipantSid: string | undefined;
	twilioTherapistParticipantSid: string | undefined;
	tokenAllocationId: string | undefined;
	dateTimeCreated: string;
	dateTimeUpdated: string | undefined;
	dateTimeRejected: string | undefined;
	dateTimeCancelled: string | undefined;
	dateTimeAccepted: string | undefined;
	proBonoType: string | undefined;
}

interface IPatient {
	referralCode: string | undefined;
	mweInterventionComplete: boolean | undefined;
	notes: string | undefined;
	subscriptions: ISubscription[];
	diagnostics: {
		latestDiagnostic: IPatientDiagnostic | undefined;
		reportedSuicidalThoughts: boolean;
	};
	matches: IPatientMatch[];
}

export interface IUser extends IAuthUser {
	gender: string | undefined;
	Patient: IPatient;
}

const formatBillableStatus = (billableStatus: APIBillingStatus | null): ReactElement => {
	switch (billableStatus) {
		case `BILLABLE`:
			return (
				<div className="flex items-center justify-center">
					<CheckCircle size={12} className="inline text-green-700 mr-2" />
					Billable
				</div>
			);
		case `NOT_BILLABLE`:
			return (
				<div className="flex items-center justify-center">
					<XCircle size={12} className="inline text-red-700 mr-2" />
					Not Billable
				</div>
			);
		default:
			return (
				<div className="flex items-center justify-center">
					<XCircle size={12} className="inline text-red-700 mr-2" />
					Unknown
				</div>
			);
	}
};

const formatBookingType = (bookingType: APIBookingType): string => {
	switch (bookingType) {
		case `CONSULTATION`:
			return `Consultation`;
		case `REGULAR`:
			return `Regular`;
		case `UNKNOWN`:
			return `Unknown`;
	}
};

interface BookingsProps {
	bookings: IBooking[];
}

const formatBookingStatus = (
	status: BookingStatus,
	missedBy: BookingParticipant | undefined,
	cancelledBy: BookingParticipant | undefined,
): string => {
	switch (status) {
		case `UPCOMING`:
			return `Upcoming`;
		case `HELD`:
			return `Held`;
		case `MISSED`:
			return `Missed by ${missedBy?.toLowerCase()}`;
		case `CANCELLED`:
			return `Cancelled by ${cancelledBy?.toLowerCase()}`;
		case `REJECTED`:
			return `Rejected`;
		case `PENDING`:
			return `Pending`;
	}
};

const BookingsTable = (props: BookingsProps): ReactElement => {
	const [bookings, setBookings] = useState<IBooking[]>(props.bookings);
	const [billableAdjustmentsInProgress, setBillableAdjustmentsInProgress] = useState<string[]>([]);

	useEffect(() => {
		setBookings(props.bookings);
	}, [props]);

	const handleBillableAdjustment = async (bookingId: string, status: IPatientBillingStatus): Promise<void> => {
		setBillableAdjustmentsInProgress([...billableAdjustmentsInProgress, bookingId]);

		const [apiErr, res] = await handleAPIRequest({
			method: `PATCH`,
			url: `booking/${bookingId}/billing`,
			reqBody: {
				billingStatus: status,
			},
			service: `bookings`,
		});

		if (apiErr || !res) {
			setBillableAdjustmentsInProgress(billableAdjustmentsInProgress.filter(id => id !== bookingId));
			log.unhappy(`Changing billable status failed.`, { error: apiErr });
			return;
		}

		setBillableAdjustmentsInProgress(billableAdjustmentsInProgress.filter(id => id !== bookingId));

		if (res.status === 200) {
			setBookings(
				bookings.map(booking =>
					booking.bookingId === bookingId
						? {
								...booking,
								billing: {
									status: status,
									changeReason: `Manual change`,
								},
						  }
						: booking,
				),
			);
		}
		return;
	};

	return bookings.length ? (
		<table className="table-auto rounded bg-gray-100 w-full text-center border-separate border">
			<thead className="border-b p-4">
				<tr>
					<th className="p-1 font-normal">Therapist</th>
					<th className="p-1 font-normal">Start Time</th>
					<th className="p1 font-normal">Status</th>
					<th className="p-1 font-normal">Type</th>
					<th className="p-1 font-normal">Billable</th>
					<th className="p-1 font-normal">Options</th>
				</tr>
			</thead>
			<tbody>
				{bookings.map(booking => (
					<tr className="bg-white" key={booking.bookingId}>
						<td className="p-1">
							{booking.therapist.firstName} {booking.therapist.lastName}
						</td>
						<td className="p-1">{formatDate(new Date(booking.slot.startDateTimeUTC), `dd/MM/yyyy HH:mm`)}</td>
						<td className="p-1">{formatBookingStatus(booking.status, booking.missedBy, booking.cancelledBy)}</td>
						<td className="p-1">{formatBookingType(booking.type)}</td>
						<td className="p-1">{formatBillableStatus(booking.billing.status)}</td>
						<td className="p-1 flex justify-center">
							<Button
								onClick={async (): Promise<void> =>
									handleBillableAdjustment(
										booking.bookingId,
										booking.billing.status === `BILLABLE` ? `NOT_BILLABLE` : `BILLABLE`,
									)
								}
								loading={billableAdjustmentsInProgress.includes(booking.bookingId)}
								disabled={billableAdjustmentsInProgress.includes(booking.bookingId)}
								text={`Set as ${booking.billing.status === `BILLABLE` ? `not` : ``} billable`}
								className="my-2"
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	) : (
		<span className="text-gray-400">No sessions to show</span>
	);
};

interface SessionsProps {
	bookings: IBooking[];
}

const Sessions = ({ bookings }: SessionsProps): ReactElement => {
	const upcomingSessions = bookings.filter(booking => new Date(booking.slot.startDateTimeUTC) > new Date());
	const pastSessions = bookings.filter(booking => new Date(booking.slot.startDateTimeUTC) < new Date());

	return (
		<>
			<h5 className="font-medium mb-4">Upcoming sessions</h5>
			<BookingsTable bookings={upcomingSessions} />
			<h5 className="font-medium mb-4 mt-4">Past sessions</h5>
			<BookingsTable bookings={pastSessions} />
		</>
	);
};

const roundTo2DP = (num: number): number => {
	return Math.round((num + Number.EPSILON) * 100) / 100;
};

interface MatchesProps {
	data: IUser;
	onEditMatch: (matchId: string) => void;
}

const Matches = ({ data: { Patient }, onEditMatch }: MatchesProps): ReactElement => {
	return Patient.matches.length ? (
		<table
			className={`
				table-auto
				border-separate border rounded
				bg-gray-100
				w-full
				text-center
			`}
		>
			<thead className="border-b p-4">
				<tr>
					<th className="p-1 font-normal">Therapist</th>
					<th className="p-1 font-normal">Score</th>
					<th className="p-1 font-normal">Selected</th>
					<th className="p-1 font-normal">Options</th>
				</tr>
			</thead>
			<tbody>
				{Patient.matches.map(({ therapistFirstName, therapistLastName, selected, confidenceScore, matchId }) => (
					<tr className="bg-white" key={matchId}>
						<td className="p-1">
							{therapistFirstName} {therapistLastName}
						</td>
						<td className="p-1">{roundTo2DP(confidenceScore)}</td>
						<td className="p-1">{selected && <CheckCircle size={12} className="text-green-700 mx-auto" />}</td>
						<td className="p-1 flex justify-center">
							<Button
								onClick={(): void => {
									onEditMatch(matchId);
								}}
								text={`Edit`}
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	) : (
		<span className="text-gray-400">No matches to show</span>
	);
};

/**
 * Parse diagnostic form into markdown
 * @param formData - the formData string of the diagnostic
 */
const parseDiagnosticFormToMarkdown = (formData: string): string => {
	try {
		const parsedData = JSON.parse(formData);

		const practitionersGender = parsedData.practitionersGender || `-empty-`;
		const practitionersCulture = parsedData.practitionersCulture || `-empty-`;
		const mood = parsedData.individualsMood || `-empty-`;
		const moodCause = parsedData.moodSelections.join(`, `) || `-empty-`;
		const otherFeelings = parsedData.additionalMoodSelections.join(`, `) || `-empty-`;
		const addictionFeelings = parsedData.addictionMoodSelections.join(`, `) || `-empty-`;
		const healthImpact = parsedData.healthAffected || `-empty-`;
		const workImpact = parsedData.abilityToWork || `-empty-`;
		const relationshipsImpact = parsedData.relationships || `-empty-`;
		const contactNumber = parsedData.individualsPhone || `-empty-`;
		const emergencyContactName = parsedData.emergencyContactName || `-empty-`;
		const emergencyContactPhone = parsedData.emergencyContactPhone || `-empty-`;

		return [
			`**Practitioner's gender:**\n\n&ensp;&ensp;${practitionersGender}`,
			`**Practitioner's culture:**\n\n&ensp;&ensp;${practitionersCulture}`,
			`**Mood over last 2 weeks:**\n\n&ensp;&ensp;${mood}`,
			`**Cause of mood:**\n\n&ensp;&ensp;${moodCause}`,
			`**Other feelings:**\n\n&ensp;&ensp;${otherFeelings}`,
			`**Addiction feelings:**\n\n&ensp;&ensp;${addictionFeelings}`,
			`**Impact on health:**\n\n&ensp;&ensp;${healthImpact}/5`,
			`**Impact on work:**\n\n&ensp;&ensp;${workImpact}/5`,
			`**Impact on relationships:**\n\n&ensp;&ensp;${relationshipsImpact}/5`,
			`**Contact number:**\n\n&ensp;&ensp;${contactNumber}`,
			`**Emergency Contact Name:**\n\n&ensp;&ensp;${emergencyContactName}`,
			`**Emergency Contact Phone:**\n\n&ensp;&ensp;${emergencyContactPhone}`,
		].join(`\n\n`);
	} catch (e: unknown) {
		log.unhappy(`Could not parse diagnostic detail`);
		return `Could not parse diagnostic detail`;
	}
};

interface DiagnosticProps {
	data: IUser;
}

const Diagnostic = ({ data: { Patient } }: DiagnosticProps): ReactElement => {
	return (
		<>
			{Patient.diagnostics.reportedSuicidalThoughts && (
				<div className="flex items-center text-red-400 mb-2">
					<AlertTriangle size={12} className="mr-1.5" />
					Patient reported suicidal thoughts
				</div>
			)}
			{Patient.diagnostics.latestDiagnostic ? (
				<ReactMarkdown>{parseDiagnosticFormToMarkdown(Patient.diagnostics.latestDiagnostic.formData)}</ReactMarkdown>
			) : (
				<span className="text-gray-400">No diagnostic to show</span>
			)}
		</>
	);
};

interface MilestonesProps {
	data: IUser;
	bookings: IBooking[];
}

const Milestones = ({ data: { Patient }, bookings }: MilestonesProps): ReactElement => {
	const [clientData, setClientData] = useState<IClient | undefined>();

	const pullClientData = async (clientId: string): Promise<void> => {
		const [err, res] = await handleAPIRequest<IClient | undefined>({ method: `get`, url: `clients/${clientId}` });

		if (err) {
			log.unhappy(`Client data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		setClientData(res.data.data);
	};

	const patientEnterpriseSubscriptions = Patient.subscriptions.filter(
		subscription => subscription.kind.type === `CLIENT`,
	);
	const patientPersonalSusbcriptions = Patient.subscriptions.filter(
		subscription => subscription.kind.type === `PERSONAL`,
	);

	useEffect(() => {
		if (patientEnterpriseSubscriptions[0]) {
			void pullClientData((patientEnterpriseSubscriptions[0].kind as IClientSubscriptionKind).clientId);
		}
	}, []);

	const patientHasEnterpriseSubscription = !!patientEnterpriseSubscriptions.length;
	const patientHasPersonalSubscription = !!patientPersonalSusbcriptions.length;

	const hasBookedConsultation = bookings.filter(booking => booking.type === `CONSULTATION`).length > 0;
	const hasAttendedConsultation =
		bookings.filter(booking => booking.type === `CONSULTATION` && booking.status === `HELD`).length > 0;
	const hasBookedFollowUp = bookings.filter(booking => booking.type === `REGULAR`).length > 0;
	const hasAttendedFollowUp =
		bookings.filter(booking => booking.type === `REGULAR` && booking.status === `HELD`).length > 0;

	return (
		<div className="flex flex-wrap">
			<div className="inline-block w-1/2 md:w-1/4">
				<h6
					className={`
						mb-2
						font-normal
						${patientHasEnterpriseSubscription || patientHasPersonalSubscription ? `text-green-600` : `text-red-600`}
					`}
				>
					Subscriptions
				</h6>
				{patientHasEnterpriseSubscription && patientEnterpriseSubscriptions[0] && (
					<span className="flex items-center">
						<CheckCircle size={12} className="inline text-green-700" />
						&nbsp; Linked to&nbsp;
						{clientData?.name ? (
							<Link className="custom-explicit-link" to={`/clients/${clientData.id}`}>
								{clientData.name}
							</Link>
						) : (
							`loading...`
						)}
					</span>
				)}
				{patientHasPersonalSubscription && (
					<span className="flex items-center">
						<CheckCircle size={12} className="inline text-green-700" />
						&nbsp; Active personal subscription
					</span>
				)}
				{!patientHasEnterpriseSubscription && !patientHasPersonalSubscription && (
					<span className="flex items-center">
						<XCircle size={12} className="inline text-red-700" />
						&nbsp; No link or subscription
					</span>
				)}
			</div>
			<div className="inline-block w-1/2 md:w-1/4">
				<h6
					className={`
						mb-2
						font-normal
						${Patient.diagnostics.latestDiagnostic ? `text-green-600` : `text-red-600`}
					`}
				>
					Diagnostic
				</h6>
				<span className="flex items-center">
					{Patient.diagnostics.latestDiagnostic ? (
						<CheckCircle size={12} className="inline text-green-700" />
					) : (
						<XCircle size={12} className="inline text-red-700" />
					)}
					&nbsp; {Patient.diagnostics.latestDiagnostic ? `Completed` : `Not completed`}
				</span>
			</div>
			<div className="inline-block w-1/2 mt-4 md:mt-0 md:w-1/4">
				<h6
					className={`
						mb-2
						font-normal
						${hasBookedConsultation && hasAttendedConsultation ? `text-green-600` : `text-red-600`}
					`}
				>
					First consultation
				</h6>
				<span className="flex items-center">
					{hasBookedConsultation ? (
						<CheckCircle size={12} className="inline text-green-700" />
					) : (
						<XCircle size={12} className="inline text-red-700" />
					)}
					&nbsp; {hasBookedConsultation ? `Booked` : `Not booked`}
				</span>
				<span className="flex items-center">
					{hasAttendedConsultation ? (
						<CheckCircle size={12} className="inline text-green-700" />
					) : (
						<XCircle size={12} className="inline text-red-700" />
					)}
					&nbsp; {hasAttendedConsultation ? `Attended` : `Not attended`}
				</span>
			</div>
			<div className="inline-block w-1/2 mt-4 md:mt-0 md:w-1/4">
				<h6
					className={`
						mb-2
						font-normal
						${hasBookedFollowUp && hasAttendedFollowUp ? `text-green-600` : `text-red-600`}
					`}
				>
					Follow-up
				</h6>
				<span className="flex items-center">
					{hasBookedFollowUp ? (
						<CheckCircle size={12} className="inline text-green-700" />
					) : (
						<XCircle size={12} className="inline text-red-700" />
					)}
					&nbsp; {hasBookedFollowUp ? `Booked` : `Not booked`}
				</span>
				<span className="flex items-center">
					{hasAttendedFollowUp ? (
						<CheckCircle size={12} className="inline text-green-700" />
					) : (
						<XCircle size={12} className="inline text-red-700" />
					)}
					&nbsp; {hasAttendedFollowUp ? `Attended` : `Not attended`}
				</span>
			</div>
		</div>
	);
};

interface DetailsProps {
	data: IUser;
	referralCode: string | undefined;
	mweInterventionComplete: boolean | undefined;
}

const Details = ({
	data: { firstName, lastName, gender, phone, dateTimeCreated },
	referralCode,
	mweInterventionComplete,
}: DetailsProps): ReactElement => {
	return (
		<div className="flex justify-between">
			<div>
				<h2 className="text-2xl">
					{firstName} {lastName}
				</h2>
				<div className="mt-4">
					Gender:&nbsp;
					{gender ? <span>{gender}</span> : <p className="inline text-gray-400">n/a</p>}
					<br />
					Tel:&nbsp;
					{phone ? (
						<a href={`tel:${phone}`} className="custom-explicit-link">
							{phone}
						</a>
					) : (
						<p className="inline text-gray-400">n/a</p>
					)}
				</div>
			</div>
			<div className="text-right">
				Registered:&nbsp;
				<span>{formatDate(new Date(dateTimeCreated), `do MMM yyyy`)}</span>
				<br />
				Referral code:&nbsp;
				{referralCode ? <span>{referralCode}</span> : <p className="inline text-gray-400">n/a</p>}
				<br />
				MWE intervention:&nbsp;
				{mweInterventionComplete ? <span>Complete</span> : <p className="inline text-gray-400">n/a</p>}
			</div>
		</div>
	);
};

interface EmailsProps {
	emails: IAuthUserEmail[];
}

const Emails = ({ emails }: EmailsProps): ReactElement => {
	return emails.length ? (
		<table
			className={`
				table-auto
				border-separate border rounded
				bg-gray-100
				w-full
				text-center
			`}
		>
			<thead className="border-b p-4">
				<tr>
					<th className="p-1 font-normal">Email</th>
					<th className="p-1 font-normal">Type</th>
					<th className="p-1 font-normal">Verified</th>
					<th className="p-1 font-normal">Created</th>
				</tr>
			</thead>
			<tbody>
				{emails.map(({ email, type, verified, dateTimeCreated, emailId }) => (
					<tr className="bg-white" key={emailId}>
						<td className="p-1">{email}</td>
						<td className="p-1">{type}</td>
						<td className="p-1">
							{verified ? (
								<CheckCircle size={12} className="inline text-green-700" />
							) : (
								<XCircle size={12} className="inline text-red-700" />
							)}
						</td>
						<td className="p1">{formatDate(new Date(dateTimeCreated), `dd/MM/yyyy HH:mm`)}</td>
					</tr>
				))}
			</tbody>
		</table>
	) : (
		<span className="text-gray-400">No emails to show</span>
	);
};

interface INotesProps {
	data: IUser;
}

const Notes = ({ data }: INotesProps): ReactElement => {
	const [text, setText] = useState(data.Patient.notes ?? ``);
	const [textLoading, setTextLoading] = useState(false);
	const [saved, setSaved] = useState<string | undefined>();

	const textLines = text.split(/\r?\n/).length;
	const rows = textLines > 3 ? textLines : 3;

	const handleNoteSave = async (): Promise<void> => {
		setTextLoading(true);

		const [apiErr, res] = await handleAPIRequest<IUser>({
			method: `PATCH`,
			url: `users/${data.authId}`,
			reqBody: {
				Patient: {
					notes: text,
				},
			},
		});

		if (apiErr || !res) {
			setTextLoading(false);
			log.unhappy(`Setting notes failed.`, { error: apiErr });
			return;
		}

		if (!res.data.data.Patient.notes) {
			return;
		}

		setTextLoading(false);
		setSaved(res.data.data.Patient.notes);
		setText(res.data.data.Patient.notes);

		return;
	};

	return (
		<>
			<h5 className="font-medium mb-4 float-left">Notes</h5>
			<Button
				className={`float-right`}
				onClick={async (): Promise<void> => handleNoteSave()}
				loading={textLoading}
				disabled={data.Patient.notes === text || saved === text}
				text={`Save Notes`}
			/>
			<textarea
				className={`
					block
					form-control
					w-full
					px-3 py-1.5
					m-0
					text-sm text-gray-700
					font-normal
					bg-yellow-100 bg-clip-padding
					border border-solid border-gray-300
					rounded
					transition
					ease-in-out
					focus:text-gray-700 focus:border-amber-300 focus:outline-none
				`}
				rows={rows}
				placeholder="Enter notes about the user here"
				value={text}
				disabled={textLoading}
				onChange={(e): void => setText(e.target.value)}
			></textarea>
		</>
	);
};

const SubscriptionsTable = (): ReactElement => {
	const [data, setData] = useRecoilState(userData);
	const [adjustSubscriptionModalOpen, setAdjustSubscriptionModalOpen] = useState(false);
	const [editingSubscription, setEditingSubscription] = useState<ISubscription | undefined>();

	const handleAdjustment = (subscription: ISubscription): void => {
		setEditingSubscription(subscription);
		setAdjustSubscriptionModalOpen(true);
	};

	const now = new Date();
	const activeSubscriptions =
		data?.Patient.subscriptions.filter(subscription => new Date(subscription.dateTimeEnd) > now) ?? [];
	const inactiveSubscriptions =
		data?.Patient.subscriptions.filter(subscription => new Date(subscription.dateTimeEnd) < now) ?? [];

	const subscriptions = [...activeSubscriptions, ...inactiveSubscriptions];

	return subscriptions.length ? (
		<>
			<table className="table-auto rounded bg-gray-100 w-full text-center border-separate border">
				<thead className="border-b p-4">
					<tr>
						<th className="p-1 font-normal">ID</th>
						<th className="p-1 font-normal">Type</th>
						<th className="p-1 font-normal">Title</th>
						<th className="p-1 font-normal">Start to End</th>
						<th className="p-1 font-normal">Consultation Sessions</th>
						<th className="p-1 font-normal">Regular Sessions</th>
						<th className="p-1 font-normal">Therapy</th>
						<th className="p-1 font-normal">MFP</th>
						<th className="p-1 font-normal">Options</th>
					</tr>
				</thead>
				<tbody>
					{subscriptions.map(subscription => (
						<tr className="bg-white" key={subscription.id}>
							<td className="p-1">{subscription.id}</td>
							<td className="p-1">{subscription.kind.type === `CLIENT` ? `Enterprise` : `Personal`}</td>
							<td className="p-1 flex justify-center">
								{subscription.title} {new Date(subscription.dateTimeEnd) < new Date() && <ExpiredPill />}
							</td>
							<td className="p-1">
								{formatDate(new Date(subscription.dateTimeStart), `dd/MM/yyyy`)} to{` `}
								{formatDate(new Date(subscription.dateTimeEnd), `dd/MM/yyyy`)}
							</td>
							<td className="p-1">
								{subscription.consultationSessions.initialQuantity} +{` `}
								{subscription.consultationSessions.additionalQuantity}
							</td>
							<td className="p-1">
								{subscription.regularSessions.initialQuantity} + {subscription.regularSessions.additionalQuantity}
							</td>
							<td className="p-1">
								{subscription.regularSessions.typesOfTherapy.therapy ? (
									<CheckCircle size={12} className="inline text-green-700" />
								) : (
									<XCircle size={12} className="inline text-red-700" />
								)}
							</td>
							<td className="p-1">
								{subscription.regularSessions.typesOfTherapy.mfp ? (
									<CheckCircle size={12} className="inline text-green-700" />
								) : (
									<XCircle size={12} className="inline text-red-700" />
								)}
							</td>
							<td className="p-1 flex justify-center">
								{subscription.kind.type === `PERSONAL` ? (
									<Button onClick={(): void => handleAdjustment(subscription)} text="Adjust" className="my-2" />
								) : (
									<Link className="text-xs custom-explicit-link" to={`/clients/${subscription.kind.clientId}`}>
										Go to client
									</Link>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{adjustSubscriptionModalOpen && (
				<Modal title="Adjust subscription" onCloseClick={(): void => setAdjustSubscriptionModalOpen(false)}>
					<EditSubscriptionModalContent
						uponSuccessCallback={(response): void => {
							setData(response as unknown as IUser);
							setAdjustSubscriptionModalOpen(false);
						}}
						subscription={editingSubscription as ISubscription}
					/>
				</Modal>
			)}
		</>
	) : (
		<span className="text-gray-400">No subscriptions to show</span>
	);
};

interface ContentProps {
	data: IUser;
	bookings: IBooking[];
	refreshData: () => Promise<void>;
}

const Content = ({ data, bookings, refreshData }: ContentProps): ReactElement => {
	const setUserData = useSetRecoilState(userData);
	const [interventionLoading, setInterventionLoading] = useState(false);
	const [interventionComplete, setInterventionComplete] = useState(data.Patient.mweInterventionComplete);
	const [referralCodeLoading, setReferralCodeLoading] = useState(false);
	const [patientReferralCode, setPatientReferralCode] = useState(data.Patient.referralCode);
	const [addSubscriptionModalOpen, setAddSubscriptionModalOpen] = useState(false);
	const [addNewMatchModelOpen, setAddNewMatchModalOpen] = useState(false);
	const [editMatchModalOpen, setEditMachModalOpen] = useState(false);
	const [matchToBeEdited, setMatchToBeEdited] = useState<string>(``);

	const history = useHistory();

	const handleInterventionBtn = async (): Promise<void> => {
		setInterventionLoading(true);

		const reqBody = {
			Patient: {
				mweInterventionComplete: !interventionComplete,
			},
		};

		const [err, res] = await handleAPIRequest<IHandleAPIRequestResponse>({
			method: `PATCH`,
			url: `users/${data.authId}`,
			reqBody,
		});

		if (err || !res) {
			setInterventionLoading(false);
			log.unhappy(`Changing MWE intervention status failed.`, { error: err });
			return;
		}

		setInterventionComplete(!interventionComplete);
		setInterventionLoading(false);

		return;
	};

	const createReferralCode = (): string => {
		let referralCode = ``;
		const characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;
		const numbers = `0123456789`;

		[...Array(3)].map(() => {
			const randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length));
			referralCode = `${referralCode}${randomCharacter}`;
		});

		[...Array(3)].map(() => {
			const randomNumber = numbers.charAt(Math.floor(Math.random() * numbers.length));
			referralCode = `${referralCode}${randomNumber}`;
		});

		return referralCode;
	};

	const addReferralCode = async (): Promise<[HandleAPIRequestError | undefined, string | undefined]> => {
		let retryCount = 0;
		let err = undefined;
		let referralCode: string | undefined;

		while (retryCount < 3) {
			referralCode = createReferralCode();

			const reqBody = {
				Patient: {
					referralCode,
				},
			};

			const [apiErr] = await handleAPIRequest<IHandleAPIRequestResponse>({
				method: `PATCH`,
				url: `users/${data.authId}`,
				reqBody,
			});

			if (apiErr === `UPDATE_USER_CONFLICT`) {
				retryCount++;
				continue;
			}

			err = apiErr;
			break;
		}

		return [err, referralCode];
	};

	const handleGenerateReferralCode = async (): Promise<void> => {
		setReferralCodeLoading(true);

		const [err, referralCode] = await addReferralCode();

		if (err || !referralCode) {
			setReferralCodeLoading(false);
			log.unhappy(`Setting referral code failed.`, { error: err });
			return;
		}

		setReferralCodeLoading(false);
		setPatientReferralCode(referralCode);

		return;
	};

	return (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			<div
				className={`
			flex
			flex-row
			justify-between
			`}
			>
				<button
					onClick={history.goBack}
					className={`
					custom-explicit-link
					text-xs
					flex items-center
				`}
				>
					<ArrowLeft size={12} className="mr-1" />
					Go back
				</button>
				<div
					className={`
						flex
						flex-row
					`}
				>
					<Button
						onClick={async (): Promise<void> => handleGenerateReferralCode()}
						loading={referralCodeLoading}
						text={`Generate referral code`}
						disabled={!!patientReferralCode}
					/>
					<Button
						className={`ml-3`}
						onClick={async (): Promise<void> => handleInterventionBtn()}
						loading={interventionLoading}
						text={`Mark intervention as ${interventionComplete ? `incomplete` : `complete`}`}
					/>
				</div>
			</div>
			<hr className="mt-6 mb-6" />
			<Details data={data} referralCode={patientReferralCode} mweInterventionComplete={interventionComplete} />
			<hr className="mt-6 mb-6" />
			<h5 className="font-medium mb-4">Milestones</h5>
			<Milestones data={data} bookings={bookings} />
			<hr className="mt-6 mb-6" />
			<div className="md:flex">
				<div className="md:flex-1">
					<h5 className="font-medium mb-4">Diagnostic</h5>
					<Diagnostic data={data} />
				</div>
				<div className="md:flex-1">
					<h5 className="font-medium mb-4 mt-4 md:mt-0">Matches</h5>
					<Button
						className={`mt-6 mb-1`}
						variant="system"
						text="+ New"
						onClick={(): void => setAddNewMatchModalOpen(true)}
					/>
					<Matches
						data={data}
						onEditMatch={(matchId: string): void => {
							setMatchToBeEdited(matchId);
							setEditMachModalOpen(true);
						}}
					/>
				</div>
			</div>
			<hr className="mt-6 mb-6" />
			<Sessions bookings={bookings} />
			<hr className="mt-6 mb-6" />
			<div className="flex items-center justify-between mb-4">
				<div className="inline">
					<h5 className="font-medium">Subscriptions</h5>
					<p className="text-xs mb-4">
						All of the subscriptions this patient is associated with, either due to their client link, or individual
						subscriptions. Client subscriptions cannot be edited here.
					</p>
				</div>
				<Button
					className={`mt-6 mb-1`}
					variant="system"
					text="+ New"
					onClick={(): void => setAddSubscriptionModalOpen(true)}
				/>
			</div>
			<SubscriptionsTable />
			<hr className="mt-6 mb-6" />
			<Notes data={data} />
			<hr className="mt-6 mb-6" />
			<h5 className="font-medium mb-4">Emails</h5>
			<Emails emails={data.emails} />
			{addSubscriptionModalOpen && (
				<Modal title="Add subscription" onCloseClick={(): void => setAddSubscriptionModalOpen(false)}>
					<AddSubscriptionModalContent
						uponSuccessCallback={(response: ISubscription): void => {
							const newPatientSubscriptions = [...data.Patient.subscriptions, response];
							const newData = {
								...data,
								Patient: {
									...data.Patient,
									subscriptions: newPatientSubscriptions,
								},
							};

							setUserData(newData);
							setAddSubscriptionModalOpen(false);
						}}
						entity="USER"
						entityId={data.authId}
					/>
				</Modal>
			)}
			{addNewMatchModelOpen && (
				<Modal title="Add New Match" onCloseClick={(): void => setAddNewMatchModalOpen(false)}>
					<AddNewMatchModalContent
						uponSuccessCallback={(): void => {
							setAddNewMatchModalOpen(false);
							void refreshData();
						}}
						patientAuthId={data.authId}
						currentMacthes={data.Patient.matches}
					/>
				</Modal>
			)}
			{editMatchModalOpen && (
				<Modal title="Edit Match" onCloseClick={(): void => setEditMachModalOpen(false)}>
					<EditMatchModalContent
						uponSuccessCallback={(): void => {
							setEditMachModalOpen(false);
							void refreshData();
						}}
						match={data.Patient.matches.find(match => match.matchId === matchToBeEdited)}
					/>
				</Modal>
			)}
		</div>
	);
};

export const Loading = (): ReactElement => {
	return (
		<div
			className={`
				flex items-center justify-center
				custom-h-full
			`}
		>
			<p className="font-extralight">Loading...</p>
		</div>
	);
};

const Page = (): ReactElement => {
	const { authId } = useParams<IParams>();
	const [patientsLoading, setPatientsLoading] = useState<boolean>(false);
	const [bookingsLoading, setBookingsLoading] = useState<boolean>(false);
	const [data, setData] = useRecoilState(userData);
	const [bookings, setBookings] = useState<IBooking[]>([]);

	const pullBookingData = async (): Promise<void> => {
		setBookingsLoading(true);
		const [bookingErr, bookingRes] = await handleAPIRequest<IBooking[] | undefined>({
			method: `GET`,
			url: `booking/user/${authId}`,
			service: `bookings`,
		});
		setBookingsLoading(false);

		if (bookingErr) {
			log.unhappy(`Patient bookings pull request failed`, { error: bookingErr });
			return;
		}

		// This is purely for TypeScript correctness:
		if (bookingRes?.data.data === undefined) {
			return;
		}

		setBookings(bookingRes.data.data);
	};

	const pullPatientData = async (): Promise<void> => {
		setPatientsLoading(true);
		const [err, res] = await handleAPIRequest<IUser | undefined>({ method: `get`, url: `users/${authId}` });
		setPatientsLoading(false);

		if (err) {
			log.unhappy(`Patient data pull request failed`, { error: err });
			return;
		}

		// This is purely for TypeScript correctness:
		if (res === undefined) {
			return;
		}

		setData(res.data.data);
	};

	useModifyPageTitle(`${data?.firstName} ${data?.lastName}`);

	useEffect(() => {
		void pullPatientData();
		void pullBookingData();
	}, []);

	return bookingsLoading || patientsLoading || !data ? (
		<Loading />
	) : (
		<Content data={data} bookings={bookings} refreshData={pullPatientData} />
	);
};

export default Page;
/* eslint-enable @typescript-eslint/naming-convention -- legacy data shim format */
