import { X } from 'react-feather';

import type { ReactElement } from 'react';

interface OnCloseCallback {
	(): void;
}

interface ModalProps {
	title: string;
	onCloseClick?: OnCloseCallback;
	children: ReactElement;
}

const Modal = ({ title, onCloseClick, children }: ModalProps): ReactElement => {
	const handleCloseClick = (): void => {
		if (onCloseClick) {
			onCloseClick();
		}
	};

	return (
		<>
			<div className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-10" onClick={handleCloseClick} />
			<div className="fixed top-0 left-0 right-0 bottom-0 z-20 flex items-center justify-center pointer-events-none">
				<div className="bg-white z-20 w-96 p-4 pointer-events-auto rounded">
					<div className="flex justify-between items-center">
						<span className="text-lg font-extralight">{title}</span>
						<button onClick={handleCloseClick}>
							<X />
						</button>
					</div>
					<hr className="my-3" />
					<div className="flex flex-col items-center justify-center h-full pt-2">{children}</div>
				</div>
			</div>
		</>
	);
};

export default Modal;
