import { useEffect, useState } from 'react';
import Button from '../Button/Button';

import { handleAPIRequest } from '../../utils/request';

import type { ReactElement } from 'react';
import type { IPatientMatch } from '../../Page/PatientsDetail';

interface AddNewMatchModalContentProps {
	uponSuccessCallback: () => void;
	patientAuthId: string;
	currentMacthes: IPatientMatch[];
}

interface ITherapistMatch {
	authId?: string;
	confidenceScore?: number;
}

interface ITherapist {
	authId: string;
	therapistId: string;
	firstName: string;
	lastName: string;
	specialisms?: string;
}

interface IPatientTherapistMatch {
	therapistId: string;
	patientId: string;
	confidenceScore: number;
}

const AddNewMatchModalContent = ({
	currentMacthes,
	patientAuthId,
	uponSuccessCallback,
}: AddNewMatchModalContentProps): ReactElement => {
	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [errored, setErrored] = useState(false);
	const [therapistsList, setTherapistsList] = useState<ITherapist[]>([]);
	const [newMatchData, setNewMatchData] = useState<ITherapistMatch>();

	const finishedRequest = !loading && !errored && completed;
	const submittable = newMatchData?.authId && newMatchData.confidenceScore;

	if (finishedRequest) {
		uponSuccessCallback();
	}

	const getTherapistsList = async (): Promise<void> => {
		setErrored(false);
		setLoading(true);

		const [err, res] = await handleAPIRequest<ITherapist[]>({
			method: `GET`,
			url: `matching/therapists`,
		});
		if (err || res?.status !== 200) {
			setErrored(true);
			return;
		}
		const allTherapists = res.data.data;
		const theraistsToDisplay = allTherapists
			.filter(therapist => !currentMacthes.find(match => match.therapistAuthId === therapist.authId))
			.sort((t1, t2) => {
				return `${t1.firstName} ${t1.lastName}`.toLowerCase() > `${t2.firstName} ${t2.lastName}`.toLowerCase() ? 1 : -1;
			});
		setTherapistsList(theraistsToDisplay);
		setLoading(false);
	};

	const createNewMatch = async (): Promise<void> => {
		if (!newMatchData) {
			return;
		}
		setLoading(true);
		const reqBody = {
			therapistAuthId: newMatchData.authId,
			patientAuthId,
			confidenceScore: newMatchData.confidenceScore,
		};
		const [err, res] = await handleAPIRequest<IPatientTherapistMatch>({
			method: `POST`,
			url: `matching/`,
			reqBody,
		});

		setLoading(false);
		if (err || res?.status !== 200) {
			setErrored(true);
		}
		setCompleted(true);
	};

	const handleTherapistSelected = (e: React.ChangeEvent<HTMLSelectElement>): void => {
		setNewMatchData({ ...newMatchData, authId: e.currentTarget.value });
	};

	const handleConfidenceScoreChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNewMatchData({ ...newMatchData, confidenceScore: parseFloat(e.target.value) });
	};

	useEffect(() => {
		const loadTherapists = async (): Promise<void> => {
			await getTherapistsList();
		};
		void loadTherapists();
	}, []);

	return (
		<>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="flex items-center mb-2">
				<label className="text-sm flex-shrink-0 mr-3">Therapist:</label>
				<select
					onChange={handleTherapistSelected}
					id="therapists"
					className="bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
				>
					<option selected>Select a therapist</option>
					{therapistsList.map(therapist => {
						return <option value={therapist.authId}>{`${therapist.firstName} ${therapist.lastName}`}</option>;
					})}
				</select>
			</div>
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Confidence Score:</span>
				<input
					type="number"
					min={0.1}
					max={20}
					step={0.1}
					className="w-48 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={newMatchData?.confidenceScore ?? 0}
					onChange={handleConfidenceScoreChange}
				/>
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={createNewMatch}
				disabled={!submittable}
				text={`Create Match`}
			/>
		</>
	);
};

export default AddNewMatchModalContent;
