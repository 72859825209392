import type { ReactElement } from 'react';

interface ChoicePillProps {
	label: string;
	active: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChoicePill = ({ label, active, onClick }: ChoicePillProps): ReactElement => {
	return (
		<button
			className={`
				py-1 px-3
				border border-blue-300
				rounded
				mr-2
				text-xs
				${active ? `bg-blue-300` : ``}
			`}
			onClick={onClick}
		>
			{label}
		</button>
	);
};
