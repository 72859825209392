import { AnimatePresence } from 'framer-motion';

import type { ReactNode, ReactElement } from 'react';

interface IAlertList {
	children: ReactNode;
}

const AlertList = (props: IAlertList): ReactElement => {
	const { children } = props;

	return (
		<div
			className={`
				mt-8 mx-auto
				p-4
				custom-width-1080
			`}
		>
			<AnimatePresence>{children}</AnimatePresence>
		</div>
	);
};

export default AlertList;
