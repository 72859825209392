import { useEffect, useState } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { format as formatDate, subMonths } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import { handleAPIRequest } from '../utils/request';
import { log } from '../utils/logger';
import { Loading } from './PatientsDetail';
import { useModifyPageTitle } from '../Hooks/hooks';

import type { ReactElement } from 'react';
import type { GridRowsProp, GridColDef } from '@mui/x-data-grid';

const matchingStatsList = atom<IMatchingStats[]>({
	key: `matching-stats-list`,
	default: [],
});

export interface IMatchingStats {
	therapistId: string;
	therapistAuthId: string;
	name: string;
	displayedTop3: number;
	displayRate: number;
	successDisplayRate: number;
	selected: number;
	selectedRate: number;
	totalPatientsMatched: number;
}

export interface IMatchingStatsResponse {
	startDate?: string;
	endDate?: string;
	stats: IMatchingStats[];
}

interface RecentMonth {
	year: number;
	month: number;
	label: string;
}

const getRecentMonths = (): RecentMonth[] => {
	const today = new Date();
	const months = [];
	for (let i = 1; i < 7; i++) {
		const date = subMonths(today, i);
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		months.push({
			year,
			month,
			label: formatDate(date, `MMMM yyyy`),
		});
	}
	return months;
};

interface ChoicePillProps {
	label: string;
	active: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ChoicePill = ({ label, active, onClick }: ChoicePillProps): ReactElement => {
	return (
		<button
			className={`
				py-1 px-3
				border border-blue-300
				rounded
				mr-2
				text-xs
				${active ? `bg-blue-300` : ``}
			`}
			onClick={onClick}
		>
			{label}
		</button>
	);
};

const MatchingStatsTable = (): ReactElement => {
	const data = useRecoilValue(matchingStatsList);
	const [searchQuery, setSearchQuery] = useState<string>();
	const [searchFilter, setSearchFilter] = useState(data);

	const rows: GridRowsProp = searchFilter.map((matchingStatEntry, idx) => ({
		id: idx,
		name: matchingStatEntry.name,
		displayTop3: matchingStatEntry.displayedTop3,
		displayRate: matchingStatEntry.displayRate,
		successDisplayRate: matchingStatEntry.successDisplayRate,
		selected: matchingStatEntry.selected,
		selectedRate: matchingStatEntry.selectedRate,
	}));

	const columns: GridColDef[] = [
		{ field: `name`, headerName: `Name`, flex: 1 },
		{ field: `displayTop3`, headerName: `Displayed`, type: `number`, width: 150 },
		{ field: `displayRate`, headerName: `Displayed Rate (%)`, type: `number`, width: 200 },
		{ field: `selected`, headerName: `Selected`, type: `number`, width: 150 },
		{ field: `selectedRate`, headerName: `Selected Rate (%)`, type: `number`, width: 150 },
		{ field: `successDisplayRate`, headerName: `Success Rate (%)`, type: `number`, width: 150 },
	];

	const filterMatchingStats = (matchingStats: IMatchingStats[], query: string): IMatchingStats[] => {
		if (!query) {
			return matchingStats;
		}

		return matchingStats.filter(matchingStatEntry => {
			const name = matchingStatEntry.name.toLowerCase();
			const searchTerm = `${name}`;
			return searchTerm.includes(query);
		});
	};

	useEffect(() => {
		if (searchQuery) {
			setSearchFilter(filterMatchingStats(data, searchQuery.toLowerCase()));
		}
		if (!searchQuery) setSearchFilter(data);
	}, [searchQuery, data]);

	return (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			<div className="p-1 font-normal">
				<span className="text-2xl">Patients Matched: {data[0]?.totalPatientsMatched}</span>
			</div>

			{/* SEARCH BOX */}
			<div className="flex justify-center">
				<div className="mb-3 xl:w-96">
					<div className="input-group relative flex flex-wrap items-stretch w-full mb-4 rounded">
						<input
							type="search"
							className="form-control relative flex-auto block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							placeholder="Search"
							aria-label="Search"
							onInput={(e): void => setSearchQuery(e.currentTarget.value)}
						/>
					</div>
				</div>
			</div>

			{/* MATCHING STATS TABLE || NO RESULTS */}
			{searchFilter.length ? (
				<div style={{ height: 600, width: `100%` }}>
					<DataGrid
						rows={rows}
						columns={columns}
						initialState={{
							sorting: { sortModel: [{ field: `name`, sort: `asc` }] },
						}}
					/>
				</div>
			) : (
				<div className="flex justify-center">
					<span className="text-gray-400">No matching stats to show</span>
				</div>
			)}
		</div>
	);
};

const Page = (): ReactElement => {
	const [loading, setLoading] = useState<boolean>(false);
	const [filterMonthIdx, setFilterMonthIdx] = useState<number | undefined>();

	const recentMonths = getRecentMonths();
	const setData = useSetRecoilState(matchingStatsList);

	const pullMatchingStatsData = async (): Promise<void> => {
		setLoading(true);

		let query = ``;
		if (filterMonthIdx !== undefined && recentMonths[filterMonthIdx]) {
			query = `?month=${recentMonths[filterMonthIdx]?.month}&year=${recentMonths[filterMonthIdx]?.year}`;
		}
		const [err, res] = await handleAPIRequest<IMatchingStatsResponse | undefined>({
			method: `get`,
			url: `matching/stats${query}`,
		});

		setLoading(false);

		if (err || !res?.data.data) {
			log.unhappy(`Matching stats data pull request failed`, { error: err });
			return;
		}
		setData(res.data.data.stats);
	};

	useModifyPageTitle(`Matching Stats`);

	useEffect(() => {
		void pullMatchingStatsData();
	}, [filterMonthIdx]);

	return loading ? (
		<Loading />
	) : (
		<div
			className={`
			mx-auto
			custom-width-1080
			py-8 px-4
			font-extralight
			text-sm
		`}
		>
			<div
				className={`
			flex
			flex-row
			justify-between
			`}
			>
				<span className="text-2xl">Matching Stats</span>
			</div>
			<hr className="mt-6 mb-6" />
			<div className="flex items-center">
				<p className="mr-3">Filter matching stats by:</p>
				<ChoicePill
					label="All"
					active={filterMonthIdx === undefined}
					onClick={(): void => setFilterMonthIdx(undefined)}
				/>
				{recentMonths.map((month, idx) => (
					<ChoicePill
						label={month.label}
						active={filterMonthIdx === idx}
						onClick={(): void => setFilterMonthIdx(idx)}
					/>
				))}
			</div>
			<MatchingStatsTable />
		</div>
	);
};

export default Page;
