import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { deleteState } from '../utils/auth';
import { handleAuthRequest } from '../utils/request';

import type { ReactElement } from 'react';

const Logout = (): ReactElement => {
	const [loading, setLoading] = useState(false);
	const [tokenInvalidated, setTokenInvalidated] = useState(false);
	const [requestErrored, setRequestErrored] = useState(false);

	useEffect(() => {
		const invalidateToken = async (): Promise<void> => {
			setLoading(true);
			const [err] = await handleAuthRequest<undefined>(`POST`, `logout`);

			if (err) {
				setLoading(false);
				setRequestErrored(true);
				return;
			}

			setLoading(false);
			deleteState();
			setTokenInvalidated(true);
		};

		void invalidateToken();
	}, []);

	if (tokenInvalidated) {
		return <Redirect to="/login" />;
	}

	return (
		<div
			className={`
				flex items-center justify-center
				custom-h-full
		`}
		>
			<h1 className="text-center font-extralight">
				{loading && `Please wait...`}
				{requestErrored && `There was an error when logging you out.`}
			</h1>
		</div>
	);
};

export default Logout;
