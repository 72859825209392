import { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Logo from './insideout-logo.png';
import { handleAuthRequest } from '../utils/request';
import { log } from '../utils/logger';
import { isAuthenticated, setSessionState } from '../utils/auth';
import { useModifyPageTitle } from '../Hooks/hooks';

import type { ReactElement } from 'react';

interface ILoginResponse {
	accessToken: string;
	result: string;
}

const LoginForm = (): ReactElement => {
	const [email, setEmail] = useState(``);
	const [password, setPassword] = useState(``);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [loggedIn, setLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault();
		setLoading(true);

		const [error, response] = await handleAuthRequest<ILoginResponse>(`POST`, `login`, {
			email,
			password,
		});

		setLoading(false);

		if (error) {
			setErrorMessage(error);
			return;
		}

		// Purely for TypeScript correctness:
		if (!response) {
			return;
		}

		const { data, status, statusText } = response;

		if (status !== 200) {
			setErrorMessage(statusText);
			return;
		}

		const [setSessionStateErr] = await setSessionState(data.data.accessToken);

		if (setSessionStateErr) {
			log.unhappy(`Session state set error`, { error: setSessionStateErr });

			switch (setSessionStateErr) {
				case `DECODE_TOKEN_ERROR`:
					setErrorMessage(`Error decoding the access token`);
					return;

				case `USER_NOT_ADMIN_ERROR`:
					setErrorMessage(`You do not have permission to access this resource.`);
					return;

				default:
					return;
			}
		}

		setLoggedIn(true);
	};

	if (loggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={`
			flex items-center justify-center
			min-h-full 
			py-12 px-4 sm:px-6 lg:px-8
			max-w-[350px]
		`}
		>
			<div className="max-w-md w-full space-y-8">
				<div>
					<img src={Logo} className={`mx-auto`} />
				</div>
				{errorMessage && (
					<div
						className={`
						flex items-center
						px-3 py-2
						w-full
						bg-red-100
						rounded
						text-sm
					`}
					>
						There was an error. {errorMessage}
					</div>
				)}
				<form className="mt-8 space-y-6" onSubmit={handleSubmit}>
					<input type="hidden" name="remember" value="true" />
					<div className="rounded-md shadow-sm -space-y-px">
						<div>
							<label className="sr-only text-sm">Email address</label>
							<input
								id="email-address"
								name="email"
								type="email"
								autoComplete="email"
								required
								className={`
									block
									relative
									w-full
									px-3 py-2
									border border-gray-300 rounded-t-md focus:border-gray-500
									placeholder-gray-500
									text-gray-900 sm:text-sm
									focus:outline-none
									focus:ring-gray-500
									focus:z-10
									appearance-none
								`}
								placeholder="Email address"
								value={email}
								onChange={(event): void => setEmail(event.target.value)}
							/>
						</div>
						<div className={`mt-2`}>
							<label className="sr-only text-sm">Password</label>
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								required
								className={`
									block
									relative
									w-full
									px-3 py-2
									border border-gray-300 rounded-b-md focus:border-gray-500
									placeholder-gray-500
									text-gray-900 sm:text-sm 
									focus:outline-none
									focus:ring-gray-500
									focus:z-10
									appearance-none
								`}
								placeholder="Password"
								value={password}
								onChange={(event): void => setPassword(event.target.value)}
							/>
						</div>
					</div>

					<div>
						<button
							type="submit"
							className={`
								group
								flex justify-center
								relative
								w-full
								mt-2
								py-2 px-4
								border border-transparent rounded-md
								text-white text-sm
								font-medium
								bg-gray-600 hover:bg-gray-700
								focus:outline-none
								focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
							`}
							disabled={loading}
						>
							{loading ? `Please wait...` : `Sign In`}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const Login = (): ReactElement => {
	useModifyPageTitle(`Login`);

	if (isAuthenticated()) {
		return <Redirect to="/" />;
	}

	return (
		<div
			className={`
				flex justify-center items-center
				h-full
				bg-gray-100
			`}
		>
			<LoginForm />
		</div>
	);
};

export default Login;
