import { v4 as uuidV4 } from 'uuid';

import config from '../config/config';
import { log } from '../utils/logger';
import { getSessionState } from '../utils/auth';

import type { IBodyBase, ReturnTuplePromise } from '../definitions';

export type HandleAPIRequestError =
	| `PARSE_API_REQUEST_ERROR`
	| `NETWORK_API_REQUEST_ERROR`
	| `UNKNOWN_HANDLE_API_REQUEST_ERROR`
	| `UNAUTHORISED`
	| `UPDATE_USER_CONFLICT`;

interface IAPIResponse<IReqBody = IBodyBase> {
	success: boolean;
	status: number;
	data: IReqBody;
	error?: {
		id: string;
		details: string;
	};
}

export interface IHandleAPIRequestResponse<IReqBody = IBodyBase> {
	status: number;
	statusText: string;
	data: IAPIResponse<IReqBody>;
}

interface IFetchOpts {
	method: string;
	body: string | undefined;
	headers: {
		[key: string]: string;
	};
	mode: RequestMode;
	credentials: RequestCredentials;
}

type APIService = `idb-microservice` | `bookings`;

interface IAPIRequestOpts {
	method: string;
	url: string;
	reqBody?: IBodyBase;
	service?: APIService;
}

/**
 * Get the URL to use for the API request.
 * @param service - The API service to use
 */
const getServiceURL = (service: APIService): string => {
	switch (service) {
		case `idb-microservice`:
			return `${config.API.URL}/v2/${service}`;
		case `bookings`:
			return `${config.API.BOOKINGS_URL ?? config.API.URL}/v2/${service}`;
	}
};

/**
 * Trigger a HTTP request to the API
 * @param method - The HTTP method to use
 * @param url - The relative URL of the resource in the API
 * @param reqBody - The body JSON
 */
export async function handleAPIRequest<IReqBody = IBodyBase>({
	method,
	url,
	reqBody,
	service,
}: IAPIRequestOpts): ReturnTuplePromise<HandleAPIRequestError, IHandleAPIRequestResponse<IReqBody>> {
	try {
		const opts: IFetchOpts = {
			method,
			body: reqBody ? JSON.stringify(reqBody) : undefined,
			headers: {
				'content-type': `application/json`,
				'correlation-id': uuidV4(),
			},
			mode: `cors`,
			credentials: `include`,
		};

		const sessionState = getSessionState();

		if (sessionState?.authenticated) {
			opts.headers.authorization = `Bearer ${sessionState.accessToken}`;
		}

		log.trace(`Sending request to API`, {
			method: method.toLowerCase(),
			url,
			body: reqBody,
		});

		const serviceURL = getServiceURL(service ?? `idb-microservice`);
		const res = await fetch(`${serviceURL}/${url}`, opts);
		const resBody = res.status === 202 ? {} : await res.json();

		if (res.status === 403) {
			log.unhappy(`Token refresh denied`);
			window.location.replace(`/logout`);

			return [`UNAUTHORISED`, undefined];
		}

		if (url.includes(`users/`) && method === `PATCH` && res.status === 409) {
			log.unhappy(`Conflict updating user`);
			return [`UPDATE_USER_CONFLICT`, undefined];
		}

		const newAccessToken = res.headers.get(`x-ins-refreshed-access-token`);
		if (newAccessToken) {
			const payload = {
				...sessionState,
				accessToken: newAccessToken,
			};
			localStorage.setItem(`ins-session-state`, JSON.stringify(payload));
		}

		return [
			undefined,
			{
				status: res.status,
				statusText: res.statusText,
				data: resBody,
			},
		];
	} catch (e: unknown) {
		// Network errors (via fetch) will also be caught here, see https://www.npmjs.com/package/node-fetch#handling-exceptions
		const err = e as Error;

		if (err instanceof SyntaxError) {
			log.debug(`JSON parse syntax error`, { err: err.message, name: err.name });
			return [`PARSE_API_REQUEST_ERROR`, undefined];
		}

		if (err instanceof TypeError) {
			log.debug(`Network error encountered by fetch()`, { err: err.message, name: err.name });
			return [`NETWORK_API_REQUEST_ERROR`, undefined];
		}

		log.debug(`Unknown handleAPIRequest error`, { err: err.message, name: err.name });
		return [`UNKNOWN_HANDLE_API_REQUEST_ERROR`, undefined];
	}
}

/**
 * Trigger a HTTP request to the Auth service
 * @param method - The HTTP method to use
 * @param url - The relative URL of the resource in the Auth service
 * @param reqBody - The body JSON
 */
export async function handleAuthRequest<IReqBody = IBodyBase>(
	method: string,
	url: string,
	reqBody?: IBodyBase,
): ReturnTuplePromise<HandleAPIRequestError, IHandleAPIRequestResponse<IReqBody>> {
	try {
		const opts: IFetchOpts = {
			method,
			body: reqBody ? JSON.stringify(reqBody) : undefined,
			headers: {
				'content-type': `application/json`,
				'correlation-id': uuidV4(),
			},
			mode: `cors`,
			credentials: `include`,
		};

		const sessionState = getSessionState();

		if (sessionState?.authenticated) {
			opts.headers.authorization = `Bearer ${sessionState.accessToken}`;
		}

		log.trace(`Sending request to Auth MS`, {
			method: method.toLowerCase(),
			url,
			body: reqBody,
		});
		const res = await fetch(`${config.AUTH_URL}/${url}`, opts);
		const resBody = res.status === 202 ? {} : await res.json();

		if (res.status === 403 && url !== `logout`) {
			log.unhappy(`Token refresh denied`);
			window.location.replace(`/logout`);

			return [`UNAUTHORISED`, undefined];
		}

		return [
			undefined,
			{
				status: res.status,
				statusText: res.statusText,
				data: resBody,
			},
		];
	} catch (e: unknown) {
		// Network errors (via fetch) will also be caught here, see https://www.npmjs.com/package/node-fetch#handling-exceptions
		const err = e as Error;

		if (err instanceof SyntaxError) {
			log.debug(`JSON parse syntax error`, { err: err.message, name: err.name });
			return [`PARSE_API_REQUEST_ERROR`, undefined];
		}

		if (err instanceof TypeError) {
			log.debug(`Network error encountered by fetch()`, { err: err.message, name: err.name });
			return [`NETWORK_API_REQUEST_ERROR`, undefined];
		}

		log.debug(`Unknown handleAuthRequest error`, { err: err.message, name: err.name });
		return [`UNKNOWN_HANDLE_API_REQUEST_ERROR`, undefined];
	}
}
