import { Loader } from 'react-feather';

import type { ReactElement, MouseEventHandler } from 'react';

interface IButtonProps {
	text: string | ReactElement;
	variant?: string;
	className?: string;
	loading?: boolean;
	onClick?: MouseEventHandler | undefined;
	disabled?: boolean;
}

const Button = (props: IButtonProps): ReactElement => {
	const { variant, loading, className, onClick, text, disabled } = props;

	return (
		<button
			className={`
        flex justify-center items-center
        border-2
        px-5 py-1
        text-[0.8rem] text-gray-600
        rounded
        transition-colors
        ${
					variant === `primary`
						? `border-green-400 bg-green-50 hover:bg-green-100 active:bg-green-200`
						: variant === `secondary`
						? `border-blue-400 bg-blue-50 hover:bg-blue-100 active:bg-blue-200`
						: `border-gray-400 bg-gray-50 hover:bg-gray-100 active:bg-gray-200`
				}
        ${loading || disabled ? `opacity-50 pointer-events-none` : ``}
        ${className}
      `}
			onClick={onClick ? onClick : (): void => undefined}
		>
			{loading ? <Loader size={17} className={`stroke-current animate-spin-slow mr-2`} /> : <></>}
			{loading ? `Loading...` : text}
		</button>
	);
};

export default Button;
