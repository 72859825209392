import { useState } from 'react';
import Button from '../Button/Button';

import { handleAPIRequest } from '../../utils/request';

import type { ReactElement } from 'react';
import type { IPatientMatch } from '../../Page/PatientsDetail';

interface AddNewMatchModalContentProps {
	uponSuccessCallback: () => void;
	match?: IPatientMatch;
}

interface IPatientTherapistMatch {
	therapistId: string;
	patientId: string;
	confidenceScore: number;
}

const EditMatchModalContent = ({ match, uponSuccessCallback }: AddNewMatchModalContentProps): ReactElement => {
	if (!match) return <></>;

	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [errored, setErrored] = useState(false);
	const [matchData, setMatchData] = useState<IPatientMatch>(match);

	const finishedRequest = !loading && !errored && completed;
	const submittable = matchData.confidenceScore !== match.confidenceScore;

	if (finishedRequest) {
		uponSuccessCallback();
	}

	const updateMatch = async (): Promise<void> => {
		setLoading(true);
		const reqBody = {
			confidenceScore: matchData.confidenceScore,
		};
		const [err, res] = await handleAPIRequest<IPatientTherapistMatch>({
			method: `PATCH`,
			url: `matching/${matchData.matchId}`,
			reqBody,
		});

		setLoading(false);
		if (err || res?.status !== 200) {
			setErrored(true);
		}
		setCompleted(true);
	};

	const handleConfidenceScoreChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setMatchData({ ...matchData, confidenceScore: parseFloat(e.target.value) });
	};

	return (
		<>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Therapist:</span>
				<input
					type="text"
					className="w-48 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={`${matchData.therapistFirstName} ${matchData.therapistLastName}`}
					disabled={true}
				/>
			</div>
			<div className="flex items-center mb-2">
				<span className="text-sm flex-shrink-0 mr-3">Confidence Score:</span>
				<input
					type="number"
					min={0.1}
					max={20}
					step={0.1}
					className="w-48 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={matchData.confidenceScore}
					onChange={handleConfidenceScoreChange}
				/>
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={updateMatch}
				disabled={!submittable}
				text={`Update Match`}
			/>
		</>
	);
};

export default EditMatchModalContent;
