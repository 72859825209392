import { useState } from 'react';
import { format as formatDate, parse as parseDate, isValid, formatISO } from 'date-fns';
import { InlineCheckBox } from '../CheckBox';
import Button from '../Button/Button';

import { handleAPIRequest } from '../../utils/request';

import type { ReactElement } from 'react';
import type { ISubscription } from '../../Page/Clients';

interface IEditSubscriptionModalProps {
	uponSuccessCallback: (response: ISubscription) => void;
	subscription: ISubscription;
}

const EditSubscriptionModalContent = ({
	uponSuccessCallback,
	subscription,
}: IEditSubscriptionModalProps): ReactElement => {
	const [errored, setErrored] = useState(false);
	const [endDate, setEndDate] = useState(formatDate(new Date(subscription.dateTimeEnd), `dd/MM/yyyy`));
	const [additionalConsultationSessions, setAdditionalConsultationSessions] = useState(
		`${subscription.consultationSessions.additionalQuantity}`,
	);
	const [additionalRegularSessions, setAdditionalRegularSessions] = useState(
		`${subscription.regularSessions.additionalQuantity}`,
	);
	const [therapyEnabled, setTherapyEnabled] = useState(subscription.regularSessions.typesOfTherapy.therapy);
	const [mfpEnabled, setMfpEnabled] = useState(subscription.regularSessions.typesOfTherapy.mfp);
	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [response, setResponse] = useState<ISubscription | undefined>();

	const finishedUpdate = !loading && !errored && completed;
	const changed =
		endDate !== subscription.dateTimeEnd ||
		additionalConsultationSessions !== `${subscription.consultationSessions.additionalQuantity}` ||
		additionalRegularSessions !== `${subscription.regularSessions.additionalQuantity}` ||
		therapyEnabled !== subscription.regularSessions.typesOfTherapy.therapy ||
		mfpEnabled !== subscription.regularSessions.typesOfTherapy.mfp;
	const isInvalidDate = !isValid(parseDate(endDate, `dd/MM/yyyy`, new Date()));
	const isInvalidAdditionalSessions =
		isNaN(parseInt(additionalConsultationSessions, 10)) || isNaN(parseInt(additionalRegularSessions, 10));

	if (finishedUpdate && response) {
		uponSuccessCallback(response);
	}

	const updateSubscription = async (): Promise<void> => {
		const subscriptionToUpdate: ISubscription = {
			...subscription,
			dateTimeEnd: formatISO(parseDate(endDate, `dd/MM/yyyy`, new Date())),
			kind: {
				...subscription.kind,
			},
			consultationSessions: {
				...subscription.consultationSessions,
				additionalQuantity: parseInt(additionalConsultationSessions, 10),
				typesOfTherapy: {
					...subscription.regularSessions.typesOfTherapy,
					therapy: therapyEnabled,
					mfp: mfpEnabled,
				},
			},
			regularSessions: {
				...subscription.regularSessions,
				additionalQuantity: parseInt(additionalRegularSessions, 10),
				typesOfTherapy: {
					...subscription.regularSessions.typesOfTherapy,
					therapy: therapyEnabled,
					mfp: mfpEnabled,
				},
			},
		};

		const reqBody = { subscription: subscriptionToUpdate };

		setErrored(false);
		setLoading(true);
		const [err, res] = await handleAPIRequest<ISubscription>({
			service: `bookings`,
			method: `PATCH`,
			url: `subscription/${subscription.id}`,
			reqBody,
		});
		setLoading(false);

		if (err || res?.status !== 200) {
			setErrored(true);
			setCompleted(true);
			return;
		}

		setResponse(res.data.data);
		setCompleted(true);
	};

	const handleSubscriptionEndDateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setEndDate(e.target.value);
	};

	const handleAdditionalConsultationSessionsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setAdditionalConsultationSessions(e.target.value);
	};

	const handleAdditionalRegularSessionsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setAdditionalRegularSessions(e.target.value);
	};

	const handleTherapyEnabledChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setTherapyEnabled(e.target.checked);
	};

	const handleMfpEnabledChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setMfpEnabled(e.target.checked);
	};

	return (
		<>
			{errored && (
				<div
					className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-yellow-100
				opacity-90`}
				>
					<p>Something went wrong. Please try again.</p>
				</div>
			)}
			<div className="flex items-center">
				<span className="text-sm flex-shrink-0 mr-3">End date:</span>
				<input
					type="text"
					className="w-auto py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={endDate}
					onChange={handleSubscriptionEndDateChange}
				/>
			</div>
			<hr className="w-72 mt-5 mb-5" />
			<div className="block items-center">
				<div className="mr-8">
					<span className="text-sm flex-shrink-0">Additional Consultation Sessions:</span>
					<p className="text-xs">
						This subscription has {subscription.consultationSessions.initialQuantity} initial session
						{subscription.consultationSessions.initialQuantity > 1 ? `s` : ``}
					</p>
				</div>
				<input
					type="text"
					className="w-12 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={additionalConsultationSessions}
					onChange={handleAdditionalConsultationSessionsChange}
				/>
				<div className="mr-8">
					<span className="text-sm flex-shrink-0">Additional Regular Sessions:</span>
					<p className="text-xs">
						This subscription has {subscription.regularSessions.initialQuantity} initial session
						{subscription.regularSessions.initialQuantity > 1 ? `s` : ``}
					</p>
				</div>
				<input
					type="text"
					className="w-12 py-1 px-2 text-sm inline-block rounded border-gray-400"
					value={additionalRegularSessions}
					onChange={handleAdditionalRegularSessionsChange}
				/>
			</div>
			<hr className="w-72 mt-5 mb-5" />
			<div className="flex items-center">
				<InlineCheckBox
					id="enable-therapy"
					checked={therapyEnabled}
					onChange={handleTherapyEnabledChange}
					label="Enable Therapy"
					highlight={false}
				/>
				<InlineCheckBox
					id="enable-mfp"
					checked={mfpEnabled}
					onChange={handleMfpEnabledChange}
					label="Enable MFP"
					highlight={false}
					className="ml-3"
				/>
			</div>
			<Button
				className={`mt-6 mb-1`}
				variant="system"
				loading={loading}
				onClick={updateSubscription}
				disabled={!changed || isInvalidDate || isInvalidAdditionalSessions}
				text="Update"
			/>
		</>
	);
};

export default EditSubscriptionModalContent;
