import jwtDecode from 'jwt-decode';
import { log } from '../utils/logger';

import type { IInsSessionState, ReturnTuplePromise } from '../definitions';
import type { IToken } from '@lettheinsideout/tokens';

type SetSessionStateError = `DECODE_TOKEN_ERROR` | `USER_NOT_ADMIN_ERROR`;

/**
 * Set the session state
 * @param accessToken - The access token (string)
 */
export const setSessionState = async (accessToken: string): ReturnTuplePromise<SetSessionStateError, void> => {
	const decodedToken: IToken = await jwtDecode(accessToken);

	if (!decodedToken.tokenData.roles.ADMIN) {
		log.unhappy(`User does not have the "staff" role`);
		return [`USER_NOT_ADMIN_ERROR`, undefined];
	}

	const payload = {
		accessToken: accessToken,
		authenticated: true,
		dateLoggedIn: new Date(),
		email: decodedToken.tokenData.email,
		errorMessage: undefined,
		loading: false,
	};

	log.happy(`Setting session state`, { payload });
	localStorage.setItem(`ins-session-state`, JSON.stringify(payload));

	return [undefined, undefined];
};

export const getSessionState = (): IInsSessionState | undefined => {
	const sessionState = localStorage.getItem(`ins-session-state`);

	if (!sessionState) {
		return;
	}

	try {
		const sessionStateJSON = JSON.parse(sessionState) as IInsSessionState;
		return sessionStateJSON;
	} catch (error: unknown) {
		return;
	}
};

export const isAuthenticated = (): boolean => {
	const sessionState = getSessionState();

	return sessionState?.authenticated ?? false;
};

export const deleteState = (): void => {
	localStorage.removeItem(`ins-session-state`);
};

export const getEmailFromState = (): string | undefined => {
	const state = getSessionState();
	if (!state) {
		return;
	}

	try {
		if (!state.email) {
			return;
		}

		return state.email;
	} catch (err: unknown) {
		return;
	}
};
